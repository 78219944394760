import {
  NewTenderCardProps,
  ResponseTenderCard,
} from "../TenderCard/TenderCardGroup";
import "./TenderTable.scss";
import SaveIcon from "@mui/icons-material/Save";
import GavelIcon from "@mui/icons-material/Gavel";
import { isBidder } from "../../utils/services";
import { useAuth } from "../Context/AuthContext";
import React from "react";
interface TenderTableProps {
  data: NewTenderCardProps;
  handleOnClickBid?: (tenderId: number) => void;
  handleOnClickSave?: (id: number) => void;
  handleOnClickTitle?: (data: ResponseTenderCard) => void;
  hasAction?: boolean;
  type: "bid" | "result";
}

interface ColorScheme {
  [key: string]: string
}
const colorScheme: ColorScheme = {
  "Request for Proposal (RFP)": "#0071BC",
  "Renting & Hiring": "#FF4500",
  "Auction": "#FF0000",
  "Amendment": "#FF1493",
  "Notice": "#008B8B",
  "Enlistment / Standing List": "#4B0082",
  "Opening of Financial Proposal": "#5C8904",
  "EOI": "#8B4513",
  "Result": "#800080",
  "Tender/Quotation": "#006400"
}

export const TenderTable: React.FC<TenderTableProps> = ({
  data,
  handleOnClickBid,
  handleOnClickSave,
  handleOnClickTitle,
  hasAction = true,
  type,
}) => {
  const { role, isAuthenticate } = useAuth();

  const getBackgroundColor = (notice_category: string) => {
    return colorScheme[notice_category]

  };

  return (
    <div className="tender-table">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th className="text-center">SN</th>
            <th>Title</th>
            <th>Company Name</th>
            <th>Notice / Award</th>
            <th>Project Category</th>
            <th>Published Date</th>
            {hasAction && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {data?.data?.map((item, index) => {
            return (
              <tr className="table-row" key={index}>
                <td className="text-center" width="3%">{index + 1}</td>
                <td width="25%">
                  <div
                    className="tender-title"
                    onClick={() => {
                      handleOnClickTitle?.(item as ResponseTenderCard);
                    }}
                    title="Click to view"
                  >
                    {item?.title}
                  </div>
                </td>
                <td width="15%">{item?.public_entity_name}</td>
                <td width="10%">
                  {" "}
                  {type === "bid" ? (
                    <div>
                      <div style={{ color: getBackgroundColor(item?.notice_category) }}>
                        {item?.notice_category}
                      </div>
                      <ul>
                        {item?.procurement_type?.map(
                          (item: any, noticeIndex: number) => {
                            return (
                              <li key={noticeIndex}>
                                {item?.label}
                              </li>

                            );
                          }
                        )}
                      </ul>

                    </div>
                  ) : (
                    <>
                      {item?.award_result?.map(
                        (award: any, awardIndex: number) => {
                          return (
                            <React.Fragment key={awardIndex}>
                              {award?.company}
                              {awardIndex !== item?.award_result?.length - 1 &&
                                ", "}
                            </React.Fragment>
                          );
                        }
                      )}
                    </>
                  )}
                </td>
                <td width="15%">
                  {item?.project_category?.map(
                    (project: any, projectIndex: number) => {
                      return (
                        <div className="category-tag" key={projectIndex}>
                          {project?.label}
                        </div>
                      );
                    }
                  )}
                </td>
                <td width="5%">
                  {item?.publishing_date} <br />{" "}
                  {item?.remaining_days === 0 ? (
                    <span className="danger-text">(Expired)</span>
                  ) : (
                    <span className="danger-text">
                      ({item?.remaining_days} days remaining)
                    </span>
                  )}
                </td>
                {hasAction && (
                  <td width="5%">
                    {(!isAuthenticate || isBidder(role)) && (
                      <div className="action-icon-block">
                        {item?.private &&
                          Number(item?.remaining_days) > 0 &&
                          item?.specification_terms_format && (
                            <div
                              className="action-icon"
                              onClick={() => {
                                handleOnClickBid?.(item?.id as number);
                              }}
                            >
                              <GavelIcon />
                            </div>
                          )}

                        {(!item?.private ||
                          !item?.specification_terms_format) && (
                            <div
                              className="action-icon"
                              onClick={() => {
                                handleOnClickSave?.(item?.id);
                              }}
                            >
                              <SaveIcon />
                            </div>
                          )}
                      </div>
                    )}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
