import Axios from "../Api/AxiosInstance";
import AxiosV2 from "../Api/AxiosInstanceV2";
import { EseewaTransactionData, KhaltiData } from "../BidFee/BidFee";
import { EnlistmentData } from "../FormikWrapper/EnlistmentFormikWrapper";
import { PublishTenderData } from "../FormikWrapper/TenderFormikWrapper";
import { VerificationDataTypes } from "../KhaltiPaymentVerification/KhaltiPaymentVerfication";
import { DataEmail } from "../Login/ForgetPw/ForgetPw";
import { VerificationData } from "../PublishFeeVerification/PublishFeeVerification";

export interface RegisterData {
  apply_as: {
    publisher: boolean;
    bidder: boolean;
  };
  first_name: string;
  last_name: string;
  middle_name: string;
  salutation: string;
  designation: string;
  password: string;
  email: string;
  username: string;
  contact_no: string;
  extra_contact_no: string;
  company_name: string;
  organization_category: string;
  office_email: string;
  website_url: string;
  province: string;
  district: string;
  municipality: string;
  company_contact_no: string;
  company_extra_contact_no: string;
  company_application_file_url: Blob;
}
export interface BoqItem {
  item_description: string;
  unit: string;
  quantity: string;
  rate: string;
  amount: string;
}

export interface LoginData {
  email: string;
  password: string;
}
export const register = (data: FormData) => {
  return AxiosV2.post("/register", data);
};

export const PostTechnicalPass = (data: any) => {
  return Axios.post("/tender_bid/technical", data);
};

export const postOpeningDate = (data: any) => {
  return Axios.post("/tender_notice/opening_date", data);
};

export const login = (data: LoginData) => {
  return Axios.post("/login", data);
};

export const publishTender = (data: PublishTenderData) => {
  return Axios.post("/tender_notice", data);
};

export const postBOQ = (postData: any) => {
  return Axios.post("/tender_boq_detail", postData);
};

export const postPetty = (postData: any) => {
  return Axios.post("/pettys", postData);
};

export const tenderBid = (id: any) => {
  return Axios.post("/tender_bid", id);
};
export const postDocument = (data: any) => {
  return Axios.post("/tender/document/upload", data);
};

export const EPaymentDataBid = (data: KhaltiData | undefined) => {
  return Axios.post("/khalti/bid_fee", data);
};

export const EPaymentDataPublish = (data: KhaltiData | undefined) => {
  return Axios.post("/khalti/publish_fee", data);
};
export const postTenderAward = (data: any) => {
  return Axios.post("/tender_award", data);
};

export interface EditPassword {
  confirm_password: string;
  password: string;
  old_password: string;
}

export const updatePassword = (passwords: EditPassword) => {
  return Axios.post("/user/change_password", passwords);
};

export const postForgetPassword = (data: DataEmail) => {
  return Axios.post("/user/send_reset_password_email/", data);
};

export const postEsewaBidFee = (data: VerificationData) => {
  return Axios.post("/esewa/bid_fee/success", data);
};
export const postEsewaPublishFee = (data: VerificationData) => {
  return Axios.post("/esewa/publish_fee/success", data);
};
export const postEsewaTransaction = (data: EseewaTransactionData) => {
  return Axios.post("/esewa/transaction", data);
};

export const saveTenderBid = (tender_id: string | number | undefined) => {
  return Axios.put(`/user_saved_tender_notice/${tender_id}`);
};

export const ignoreTenderBid = (tender_id: string | number | undefined) => {
  return Axios.delete(`/user_saved_tender_notice/${tender_id}`);
};

export const postUserInterest = (data: any) => {
  return Axios.post(`/user_interest`, data);
};

export const PostCompanyDetails = (data: any) => {
  return Axios.post("/user/organization_detail", data);
};

export const postPpmoFileDetails = (data: any) => {
  return Axios.post("/tender_boq_document", data);
};

export const postKhaltiBidFeeVerify = (data: VerificationDataTypes) => {
  return Axios.post("/khalti/bid_fee", data);
};
export const postKhaltPublishFeeVerify = (data: VerificationDataTypes) => {
  return Axios.post("/khalti/publish_fee", data);
};

export const postBidSubmission = (data: any, id: string) => {
  return Axios.put(`/tender_bid/${id}`, data);
};

export const sendOTP = (data: any) => {
  return Axios.post(`/tender_notice/send_otp`, data);
};

export const postEnlistmentQuotation = (data: EnlistmentData) => {
  return Axios.post("/tender_notice/enlistment/quotation", data);
};

export const requestAccountDeletion = (data: any) => {
  return Axios.post("/request-account-deletion", data);
};

export const deleteBidDocument = (data: any) => {
  return Axios.delete(`/tender/document/upload`, { data: data });
};

export const sendRegisterOTP = (data: any) => {
  return AxiosV2.post(`/register/otp`, data);
};

export const requestMissingTender = (data: any) => {
  return Axios.post(`/tender/missing`, data);
}
