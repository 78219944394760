const customClass = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "600px",
    width: "60%",
    zIndex: 50,
  },
};

const allBidSelectOptions = [
  {
    label: "List",
    value: "list",
  },
  {
    label: "Gallery",
    value: "image",
  },
  {
    label: "Table",
    value: "table",
  },
];

const selectOptions = [
  {
    label: "List",
    value: "list",
  },
  {
    label: "Table",
    value: "table",
  },
];

export { customClass, allBidSelectOptions, selectOptions };