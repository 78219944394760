const today = new Date();
const currentDate = today.toLocaleDateString("en-CA");
const temp = localStorage.getItem("notification");

const onEbidClick = (count:number) => {
    const tempNotification = temp ? JSON?.parse(temp as string) : {};

    const notification = {
      ...tempNotification,
      eBIdDate: currentDate,
      ebidCount: count,
    };
    if (tempNotification?.eBIdDate !== currentDate || !tempNotification) {
      return localStorage.setItem(
        "notification",
        JSON?.stringify(notification)
      );
    } else {
      return temp;
    }
  };

  const onENoticeClick = (count:number) => {
    const tempNotification = temp ? JSON?.parse(temp as string) : {};

    const notification = {
      ...tempNotification,
      eNoticeDate: currentDate,
      eNoticeCount: count,
    };
    if (tempNotification?.eNoticeDate !== currentDate || !tempNotification) {
      return localStorage.setItem(
        "notification",
        JSON?.stringify(notification)
      );
    } else {
      return temp;
    }
  };

  export {onEbidClick, onENoticeClick}