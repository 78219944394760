import React, { useContext } from "react";
import "./TenderCard.scss";
import { set } from "date-fns";
import Modal from "react-modal";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { TenderMissingForm } from "./TenderMissingForm";
import { useAuth } from "../Context/AuthContext";
import { ModalContext } from "../Context/ModalContext/ModalContext";
export interface ResponseAdvertisement {
  big_image_url: string;
  image_div_ratio: number;
  location: string;
  redirect_url: string;
  small_image_url: string;
}

interface ResponseAdvertisementProps {
  advertisement: ResponseAdvertisement | undefined;
}
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "25%",
    padding: "20px",
    maxHeight: "750px",
  },
};
export const TenderCardAd: React.FC<ResponseAdvertisementProps> = ({
  advertisement,
}) => {
  const [showTenderMissionPopup, setShowTenderMissionPopup] = React.useState<boolean>(false);
  const { isAuthenticate } = useAuth();
  const { TogglePopUpFlags } = useContext(ModalContext);

  const handleImageOnClick = (redirect_url: string) => {
    window.open(redirect_url, '_blank');
  }

  const closePopup = () => {
    setShowTenderMissionPopup(false);
  }

  return (
    <>
      <Modal
        isOpen={showTenderMissionPopup}
        onRequestClose={closePopup}
        contentLabel="My dialog"
        ariaHideApp={false}
        style={customStyles}
      >
        <TenderMissingForm onClose={() => setShowTenderMissionPopup(false)} />
      </Modal>
      {advertisement && (
        <div className="tender-in-between-section cursor-pointer">
          <div className="tender-in-between-block">
            <img src={advertisement?.big_image_url} alt="Img Not Found" onClick={() => {
              if ((advertisement?.redirect_url).toLowerCase() === 'pop-up') {
                if (isAuthenticate) {
                  setShowTenderMissionPopup(true);
                } else {
                  TogglePopUpFlags("login");
                }
                return;
              }
              handleImageOnClick(advertisement?.redirect_url)
            }} />

          </div>
        </div>
      )}
    </>
  );
};
