export enum PaymentFeeType {
  Bid = "Bid",
  Publish = "Publish",
  Subscription = "Subscription",
}

export enum PaymentStorageVariable {
  Khalti = "khalti-redirect-path",
  Esewa = "esewa-redirect-path",
}

export enum Role {
  Bidder = "Bidder",
  Publisher = "Publisher",
}

export enum PaymentStatus {
  Pending = "pending",
  Submitted = "submitted",
}

export enum BidStatus {
  IN_PROGRESS = "In_Progress",
  SUBMITTED = "Submitted",
  EOI_STATUS = "EOI_Status",
  E_NOTICE = "E-Notice",
  ENLISTMENT = "Enlistment",
  WITH_DRAWN_OR_AWARDED = "Withdrawn_Awarded",
}

export enum EOIStatus {
  Submitted = "Submitted",
  Pass = "Pass",
  Fail = "Fail",
}

export enum FileSize {
  "MAX_FILE_SIZE" = 100000000,
  "MAX_FILE_SIZE_WORD" = "File size exceeds maximum limit of 100 mb",
}

export enum NoticeCategory {
  ENLISTMENT = "Enlistment",
  TENDER_QUOTATION = "Tender/Quotation",
  AUCTION = "Auction",
  RFP = "Request for proposal",
  EOI = "EOI",
  RENT = "Rent",
}

export enum BidCategory {
  SINGLE_ENVELOPE = "single_envelope",
  DOUBLE_ENVELOPE = "double_envelope",
}

export enum TenderAwardType {
  SINGLE_BIDDER = "single_bidder",
  MULTIPLE_BIDDER = "multiple_bidder",
}

export const FileImage = {
  pdf: require("../images/pdf.png"),
  word: require("../images/word.png"),
  xls: require("../images/xls.png"),
  zip: require("../images/zip.png"),
  pic: require("../images/picture.png"),
};

export enum BidViewBtnClick {
  EXTEND_SUBMISSION_DATE = "extend_submission_date",
  OPEN_BIDS = "open_bids",
}

export enum SIGNUPAS {
  COMPANY = "Company",
  INDIVIDUAL = "Individual",
}

export enum DocumentType {
  DOCUMENT = "Document",
  CATALOGUE = "Catalogue",
  OPTIONAL = "Optional",
  FINANCIAL = "Financial",
  VENTURE = "Venture",
}

export enum BannerType {
  E_NOTICE = "E-Notice",
  PUBLISHER = "Publisher",
  BID = "Bid",
}

export enum NoticeType {
  ENOTICE = "E-Notice", 
  ENLISTMENT = "Enlistment",
  EBID = "E-Bidding",
  PPMO = "PPMO",
  NEWSPAPER = "Newspaper",
  ALL = "",
}
