import { useQuery } from "@tanstack/react-query";
import { getAllTenderNotice } from "../GetApi";
import { SearchQuery } from "../../Context/SearchContext";
import { NoticeType } from "../../../utils/EnumConstants";

type getTenderParams = {
  page: number;
  query?: SearchQuery;
  isPrivateOnly?: boolean;
  notice_type: NoticeType;
};

export const useGetTenderList = ({
  page,
  query,
  isPrivateOnly = false,
  notice_type,
}: getTenderParams) => {
  console.log("page", page);
  return useQuery({
    queryKey: ["/tender_notice", page, isPrivateOnly, notice_type, query],
    queryFn: () => getAllTenderNotice(page, query, isPrivateOnly, notice_type),
  });
};
