import { useEffect, useState } from "react";
import "./../Accordian/Accordian.scss";
import Select from "react-select";
import { SearchQuery, useSearch } from "../Context/SearchContext";
import { getTenderAttrs, getDistricts } from "../Api/GetApi";
import { toast } from "react-toastify";
import "rsuite/dist/rsuite.css";
import { formatSelectOptions } from "../utils/generic";
import { InitialSelects } from "../NewBanner/NewBanner";
export interface SelectProps {
  initialSelect: any;
  setInitialSelect: any;
  clearData: boolean;
}

type name = string;

const styles = {
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#1a1a1a",
    gridArea: "1/1/2/3",
    marginLeft: "2px",
    marginRight: "2px",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    boxSizing: "border-box",
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    cursor: "pointer",
  }),
};

const Accordian: React.FC<SelectProps> = ({
  initialSelect,
  setInitialSelect,
  clearData,
}) => {
  const { setQuery } = useSearch();

  const [procurementType, setProcurementType] = useState();
  const [projectCategory, setProjectCategory] = useState();
  const [budget, setBudget] = useState();
  const [source, setSource] = useState();
  const [noticeCategory, setNoticeCategory] = useState();
  const [district, setDistrict] = useState({});
  const [viewMore, setViewMore] = useState(false);

  // Function to set the query

  const QuerySet = (queryname: string, targetValue: string) => {
    let updatedValue = {
      [queryname]: targetValue,
    };
    setQuery((p: SearchQuery) => ({
      ...p,
      ...updatedValue,
    }));
  };

  const getQueryVal = (name: string, e: any) => {
    QuerySet(name, e === null ? "" : e?.value);
  };
  //Function to get the notice categories, procurementtyp
  const getTenderAttributes = () => {
    getTenderAttrs()
      .then((res) => {
        setProcurementType(res.data.data.procurement_type);
        setNoticeCategory(res.data.data.notice_category);
        setProjectCategory(res.data.data.project_category);
        setBudget(res.data.data.estimated_cost);
        setSource(res.data.data.source);
      })
      .catch((err) => {
        if (err) {
          toast.error("Something went wrong");
        }
      });
  };

  const getDistrict = () => {
    getDistricts()
      .then((res) => {
        setDistrict(res.data.data);
      })
      .catch((err) => {
        if (err) {
          toast.error("Something went wrong");
        }
      });
  };

  useEffect(() => {
    getTenderAttributes();
    getDistrict();
  }, []);

  return (
    <div>
      <div className="accordians position-relative">
        <div className="hide-select-section">
          <select className="select-tab">
            <option value="grapefruit">Results</option>
            <option value="lime">Bids</option>
            <option selected value="coconut">
              Organization Category
            </option>
            <option value="mango">Form</option>
          </select>
        </div>
        <div className="filter-title">
          <img src={require("../../images/Filter.svg").default} alt="filter" />
          <p className="medium-content">Filters</p>
        </div>
        <div className="border-line"></div>
        <div className={`accordion-input-section ${viewMore ? "show" : ""}`}>
          <div className="input-block ">
            {/* <div>
              <p className="filter-content  filter-options">Location</p>
            </div> */}
            <div>
              <Select
                isClearable={initialSelect.location?.value ?? false}
                menuPortalTarget={document.body}
                styles={{
                  ...styles,
                  groupHeading: (provided, state) => ({
                    ...provided,
                    color: "blue", // Change the color of the group label here
                  }),
                }}
                placeholder="Select Location"
                maxMenuHeight={200}
                value={initialSelect.location}
                onChange={(e) => {
                  getQueryVal("location" as name, e);
                  setInitialSelect((prevProps: InitialSelects) => ({
                    ...prevProps,
                    location: e,
                  }));
                }}
                options={formatSelectOptions(district)}
              />
            </div>
          </div>
          <div className="input-block ">
            {/* <p className="filter-content filter-options">Projects</p> */}
            <div>
              <Select
                isClearable={initialSelect.project?.value ?? false}
                menuPortalTarget={document.body}
                styles={styles}
                maxMenuHeight={200}
                value={initialSelect.project}
                placeholder="Select Project Type"
                onChange={(e) => {
                  getQueryVal("project_category" as name, e);

                  setInitialSelect((prevProps: InitialSelects) => ({
                    ...prevProps,

                    project: e,
                  }));
                }}
                options={projectCategory}
              />
            </div>
          </div>
          <div className="input-block">
            {/* <p className="filter-content filter-options">Notice</p> */}
            <div>
              <Select
                isClearable={initialSelect.notice?.value ?? false}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                maxMenuHeight={200}
                value={initialSelect.notice}
                placeholder="Select Notice"
                onChange={(e) => {
                  getQueryVal("notice_category" as name, e);

                  setInitialSelect((prevProps: InitialSelects) => ({
                    ...prevProps,
                    notice: e,
                  }));
                }}
                options={noticeCategory}
              />
            </div>
          </div>

          <div className="input-block ">
            {/* <p className="filter-content filter-options">Procurements</p> */}
            <div>
              <Select
                isClearable={initialSelect.procurement?.value ?? false}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                maxMenuHeight={200}
                value={initialSelect.procurement}
                placeholder="Select Procurement type"
                onChange={(e) => {
                  getQueryVal("procurement_type" as name, e);

                  setInitialSelect((prevProps: InitialSelects) => ({
                    ...prevProps,
                    procurement: e,
                  }));
                }}
                options={procurementType}
              />
            </div>
          </div>

          <div className="input-block ">
            <div>
              <Select
                isClearable={initialSelect.budget?.value ?? false}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                maxMenuHeight={200}
                value={initialSelect.budget}
                placeholder="Select Budget"
                onChange={(e) => {
                  getQueryVal("budget" as name, e);
                  setInitialSelect((prevProps: InitialSelects) => ({
                    ...prevProps,

                    budget: e,
                  }));
                }}
                options={budget}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordian;
