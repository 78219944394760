import React, { useContext, useEffect, useState } from "react";
import "../../Components/TenderCard/TenderCard.scss";
import { getSavedTenderBids } from "../../Components/Api/GetApi";
import { toast } from "react-toastify";
import { NoDataFound } from "../../Components/NoDataFound/NoDataFound";
import SavedBidBanner from "../../Components/NewBanner/SavedBidBanner";
import { AdShow } from "../../Components/AdShow/AdShow";
import "./SavedBids.scss";
import { TenderCard } from "../../Components/TenderCard/TenderCard";
import { ResultShimmer } from "../Results/ResultShimmer";
import { ResponseTenderCard } from "../../Components/TenderCard/TenderCardGroup";
import { isBidder } from "../../utils/services";
import { useAuth } from "../../Components/Context/AuthContext";
import { ModalContext } from "../../Components/Context/ModalContext/ModalContext";
export const SavedBids = () => {
  const [savedTenderBids, setSavedTenderBids] = useState<any>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [ignore, setIgnore] = useState<boolean>(false);
  const { isAuthenticate, role, userData, eligible } = useAuth();
  const { TogglePopUpFlags } = useContext(ModalContext);

  const allSavedTenderResult = () => {
    setLoading(true);
    getSavedTenderBids()
      .then((res) => {
        setSavedTenderBids(res.data?.data?.results);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      })
      .finally(() => setLoading(false));
  };
  const navigateToNoticeDetail = (data: ResponseTenderCard) => {
    const shareUrl = `${data?.private ? `bids/view/${data?.id}` : `tender-notice/${data?.id}`
      }`;

    if (!data?.id) return;
    if (data?.private && !data?.specification_terms_format) {
      window.open(`/e-notice/view/${data?.id}`, "_blank");
    }
    else {
      window.open(shareUrl, "_blank");
    }
  };
  const handleOnClickTitle = (data: ResponseTenderCard) => {
    if (isAuthenticate || data?.private) {
      if (
        isBidder(role) &&
        userData?.plan_remaining_days === 0 &&
        !data?.private
      ) {
        var planUrl = `${window.location.origin}/pricing-plan`;
        window.open(planUrl, "_blank");
      } else {
        navigateToNoticeDetail(data);
      }
    } else {
      TogglePopUpFlags("login");
    }
  };
  useEffect(() => {
    allSavedTenderResult();
  }, [ignore]);

  return (
    <div className="saved-bids">
      <div className="cardwrapper-section">
        <SavedBidBanner />
        <div className="card-wrapper-content">
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <div className="cardwrapper-section">
                  <p className="text-headers result-text">Saved Bids</p>
                  {loading ? (
                    <div style={{ textAlign: "center", marginTop: "52px" }}>
                      <ResultShimmer />
                    </div>
                  ) : (
                    <div className="saved-bids-card">
                      {savedTenderBids.length > 0 ? (
                        savedTenderBids.map((item: any, index: any) => {
                          return (
                            <React.Fragment key={index}>
                              <TenderCard
                                key={index}
                                ignore={ignore}
                                setIgnore={setIgnore}
                                savebids={true}
                                dataValue={item}
                                handleOnClickTitle={handleOnClickTitle}

                              />
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <div>
                          <NoDataFound
                            text1="No Results found"
                            text2=""
                            img={require("../../images/nodata.png")}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <AdShow />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
