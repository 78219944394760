import { MyBidLoader } from '../../Components/MyBidLoader/MyBidLoader'

export const Loading = () => {
    return (
        <div className="spinner flex-column">
            <MyBidLoader />
            <h4>Please wait..</h4>
        </div>
    )
}
