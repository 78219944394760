import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { ErrorMessage, Field, Form, Formik, yupToFormErrors } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { CustomLoader } from "../Loader/Loader";
import { DropZoneBox } from "../pdfDropzone/pdfDropzone";
import { requestMissingTender } from "../Api/PostApi";
import { toast } from "react-toastify";
import './TenderMissingForm.scss';
interface TenderMissingFormProps {
    onClose: () => void;
}

const validationSchema = Yup.object().shape({
    publishing_company_name: Yup.string()
        .required("Required"),
    published_date: Yup.string(),
    published_newspaper: Yup.string(),
    title: Yup.string(),
})
export const TenderMissingForm = ({ onClose }: TenderMissingFormProps) => {
    return <div className="login-page">
        <Formik
            initialValues={{
                "publishing_company_name": "",
                "published_date": "",
                "published_newspaper": "",
                "title": "",
                "file": "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                const formData = new FormData();
                formData.append("publishing_company_name", values.publishing_company_name);
                formData.append("published_date", values.published_date);
                formData.append("published_newspaper", values.published_newspaper);
                formData.append("title", values.title);
                formData.append("file", values.file);

                requestMissingTender(formData).then(res => {
                    toast.success(res.data.status_message || "Tender missing query submitted successfully");
                    onClose();
                }).catch(err => {
                    console.log(err);
                    toast.error("Error in submitting the form");
                }
                ).finally(() => {
                    setSubmitting(false);
                })
            }}
        >
            {({ isSubmitting, values, touched }) => {
                const touchedFields = touched.published_date || touched.published_newspaper || touched.title;
                const onOfThreeFilled = Boolean(values.published_date || values.published_newspaper || values.title)

                return (

                    <Form>
                        <div className="card">
                            <div className="popup-nav">
                                <div>
                                    <Link to="/" className="navbar-brand">
                                        <img
                                            className="logo-size"
                                            src={require("../../images/bolpart-logo-05.png")}
                                            alt="logo"
                                        />
                                    </Link>
                                </div>
                                <div
                                    className="close-icon"
                                    onClick={() => {
                                        // TogglePopUpFlags("login");
                                        onClose()
                                    }}
                                >
                                    <Icon className=" mdi-close" path={mdiClose} />
                                </div>
                            </div>

                            <div className="mt-4">
                                <h5 className="large-content">We will Find Your Notice</h5>
                            </div>
                            <div>
                                <p className="mb-2">कुन संस्था ले सुचना निकालेको हो ? <span className="red">*</span></p>
                                <div className="material-textfield">
                                    <Field
                                        name="publishing_company_name"
                                        className="text-area"
                                        placeholder=" Publising Company Name"
                                    />
                                    <span className="error">
                                        <ErrorMessage name="publishing_company_name" />
                                    </span>

                                </div>
                                <p className="mb-3"><strong>Any one compulsory from following three question <span className="red">*</span></strong></p>
                                <p className="mb-2">कुन मिति मा सुचना प्रकाशित भएको हो?</p>
                                <div className="material-textfield">
                                    <Field
                                        type="date"
                                        placeholder="Select Publishing Date"
                                        className="form-control"
                                        name="published_date"

                                    />
                                </div>
                                <p className="mb-2">कुन पत्रिका मा सूचना प्रकाशित भएको हो?</p>

                                <div className="material-textfield">
                                    <Field
                                        name="published_newspaper"
                                        className="text-area"
                                        type="text"
                                        placeholder="Published Newspaper"
                                    />
                                </div>
                                <p className="mb-2">के सम्बन्धि सूचना प्रकाशित भएको हो?</p>
                                <div className="material-textfield">
                                    <Field
                                        name="title"
                                        className="text-area"
                                        type="text"
                                        placeholder="Title of Notice"
                                    />
                                    {touchedFields && !onOfThreeFilled ? (
                                        <div className="mb-2" style={{ color: 'red' }}>
                                            You must fill at lease one of the above details
                                        </div>
                                    ) : null}
                                </div>
                                <p className="mb-2 font-bold"><strong>File Upload (Optional)</strong></p>
                                <DropZoneBox
                                    name="file"
                                    placeholder="Drag file here or Choose files from your device "
                                    label="Upload the attachment"

                                />
                                <span className="d-flex error">
                                    <ErrorMessage name="file" />
                                </span>

                            </div>
                            <div>
                                <button className="submit-btn" type="submit"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? (
                                        <>
                                            <CustomLoader
                                                type="TailSpin"
                                                color="#FFFFFF"
                                                height={25}
                                                width={52}
                                            />
                                        </>
                                    ) : (
                                        <span>Submit</span>
                                    )}
                                </button>
                            </div>
                            {/* <div className="title-header">
                    <hr></hr>
                    <p>
                    हामी तपाईंको अनुरोध टेन्डर छुटेको स्वीकृत भएको छ भन्ने सूचना दिनेछौं।
                    </p>
                </div> */}
                        </div>
                    </Form>
                )
            }}
        </Formik>
    </div >
}