import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { KhaltiPaymentInitiate } from "../../Khalti/KhaltiNewIntegration/KhaltiIntergationConfig";
import { PaymentFeeType, PaymentStatus } from "../../utils/EnumConstants";
import {
  concateToasterError,
  convertToFormData,
  isNoticeCategoryStandingList,
} from "../../utils/services";
import { postBidSubmission } from "../Api/PostApi";
import { BoqProps } from "../BOQ/BOQ";
import { BankDetail } from "../BidDetailPage/BidDetailPage";
import { BidDetailTabs } from "../BidDetailStepper/BidDetailStepper";
import { esewaPaymentInitiation } from "../EsewaPayment/EsewaPayemnt";
import {
  BidDocumentInitialValues,
  useBidFormikContext,
} from "../FormikWrapper/BidFornikWrapper";
import {
  EoiDocumentSubmitValidationSchema,
  StandingEnlistmentSubmitValidationSchema,
} from "../FormikWrapper/BidalidationSchemas";
import { Note } from "../Info";
import { JointVentureProps } from "../JointVenture/JointVenture";
import { CustomLoader } from "../Loader/Loader";
import { UploadedFile } from "../UploadedFile/UploadedFile";
import { ConfirmationPopUp } from "../PopUp/ConfirmationPopUp";
import { DropZoneBox } from "../pdfDropzone/pdfDropzone";
import { generateToken } from "../utils/GenerateToken";
import "./BidFee.scss";

export interface KhaltiData {
  token?: string | number;
  amount: string | number;
  tender_notice_id: string | number | undefined;
  fee_for: string;
}
export interface BidFeeProps extends BoqProps {
  bankDetail?: BankDetail;
  submitDocument?: any;
  bidFee?: number;
  systemBidFee?: boolean;
}
export interface InitialValues {
  voucher: string | undefined;
  type: string;
  tender_notice_id: string | undefined;
}

export interface EseewaTransactionData {
  token: any;
  amount: number;
  fee_for: PaymentFeeType;
  tender_notice_id: string | number | undefined;
  plan_id?: string | number;
}

export const BidFee: React.FC<JointVentureProps> = ({
  setPage,
  bankDetail,
  resDocument,
  bidFee,
  documentNeed,
  catalogueFiles,
  bidDetail,
}) => {
  const { id } = useParams();
  const pathname = `/my-ebids/edit/${id}?active_tab=3`;
  const token = generateToken();
  const { errors, isSubmitting, setFieldValue, values, setFieldError } =
    useBidFormikContext();
  const [isOpen, setIsOpen] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const systemBidFee = bidDetail?.systemBidFee;

  const isNoticeStandingList = isNoticeCategoryStandingList(
    bidDetail?.notice_category as string
  );

  const navigate = useNavigate();
  useEffect(() => {
    if (resDocument) {
      const completeResDocument = {
        ...resDocument.bid_documents,
        ...resDocument.boq_catalogue_files,
      };
      setFieldValue("documents.filledDocuments", completeResDocument);
    }
  }, [resDocument]);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const bidFeeSubmision = (data: BidDocumentInitialValues) => {
    const obj = { ...data.jointVenture, ...data.documents, ...data.bidFee };
    const formdata = convertToFormData(obj);
    setSubmitLoading(true);
    postBidSubmission(formdata, data.documents.tender_notice_id)
      .then((res) => {
        toast.success("Bid submitted");
        navigate("/my-ebids");
      })
      .catch((err) => {
        toast.error("Something went wrong");
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
    },
  };

  const documentAndFeeSubmission = (data: BidDocumentInitialValues) => {
    StandingEnlistmentSubmitValidationSchema(
      documentNeed as string,
      catalogueFiles as string,
      resDocument?.fee.e_payment as string
    )
      .validate(data, { abortEarly: false })
      .then(() => {
        toggleModal();
      })
      .catch((error: any) => {
        const fieldErrors: any = {};
        error.inner.forEach((error: any) => {
          setFieldError(error.path, error.message);
          fieldErrors[error.path] = error.message;
        });
        const errors = concateToasterError(fieldErrors);
        toast.error(errors);
      });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        style={customModalStyles}
        ariaHideApp={false}
      >
        <ConfirmationPopUp
          popUpContents={{
            question1: "Are you sure want to submit?",
            warn: "Note: Your company documents will be auto forwarded from your profile to publisher for bidding process. Make sure, you have uploded correct and upto date documents",
            aprroveBtn: "Submit",
            cancelBtn: "Cancel",
          }}
          toggleModal={toggleModal}
          onAprroveButton={() => {
            bidFeeSubmision(values);
          }}
          activeButtonLoading={submitLoading}
          acceptTerm={true}
        />
      </Modal>
      <div className="fees-section">
        <div className="boq-bill">
          <div className="">
            <div className="d-flex">
              <p className="detail-content ">1.Pay Bid Fee</p>
            </div>
            {systemBidFee ? (
              <>
                <Note>
                  This Bid Fee is a service charge paid to Bolpatra Nepal for
                  using its services.
                </Note>
                <div className="row">
                  <div className="col-md-6 position-relative">
                    {resDocument?.fee?.e_payment.toLowerCase() ===
                      PaymentStatus.Submitted && <div className="overlay" title="Bid Fee has already been paid for this notice.">

                      </div>}
                    <div className="pending">
                      <p className="content-left">Select Payment Method</p>
                    </div>

                    <div className="img-icon">
                      <div className="icon-block icon-spacing">
                        <img
                          src={require("../../images/esewa.png")}
                          alt="images"
                          onClick={() => {
                            esewaPaymentInitiation(
                              Number(bidFee),
                              id as string,
                              token,
                              PaymentFeeType.Bid,
                              pathname
                            );
                          }}
                        />
                      </div>

                      <div className="icon-block">
                        <img
                          src={require("../../images/khalti.png")}
                          alt="images"
                          onClick={() => {
                            KhaltiPaymentInitiate(
                              pathname,
                              PaymentFeeType.Bid,
                              Number(bidFee),
                              id
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div></div>
                  <div className="col-md-6 mg-top-45">
                    <div className="table-first">
                      <table className="table">
                        <thead>
                          <tr className="sub-row last">
                            <th>Bid Fee </th>
                            <th>
                              {resDocument && (
                                <div className="badge-parent">
                                  {resDocument?.fee?.e_payment ===
                                    PaymentStatus.Pending ? (
                                    <span className="badge-child badge badge-pill badge-warning ">
                                      Payment {resDocument?.fee?.e_payment}
                                    </span>
                                  ) : (
                                    <span className="badge-child badge-success badge badge-pill ">
                                      Payment {resDocument?.fee?.e_payment}
                                    </span>
                                  )}
                                </div>
                              )}
                            </th>
                            <th className="sub-num-last">NPR.{bidFee} </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {bidFee && bidFee > 0 ? (
                  <div className="detail-content">
                    <div className="d-flex">
                      <div className="upload-venture">
                        <div className="drop-icon col-md-10">
                          <DropZoneBox
                            name="bidFee.bid_fee_voucher"
                            placeholder="Upload Bid Fee Voucher"
                            label="Upload the cash deposit voucher to pay bid fee"
                          />
                          {errors.bidFee?.bid_fee_voucher && (
                            <span className="error">
                              {errors.bidFee?.bid_fee_voucher}
                            </span>
                          )}
                          {resDocument?.fee?.bid_fee_voucher && (
                            <UploadedFile
                              url={resDocument?.fee?.bid_fee_voucher}
                            />
                          )}
                          <div className="row ml-2">
                            <label>Voucher Date:</label>
                            <input
                              type="date"
                              className="form-control voucher-date"
                              value={values.bidFee.bid_fee_voucher_date}
                              onChange={(e: any) => {
                                setFieldValue(
                                  "bidFee.bid_fee_voucher_date",
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="content-left second-content">
                          <p>
                            Deposit the mentioned bid fee in bank information
                            provided below.
                          </p>
                        </div>
                        <div className="payement-last-fee ">
                          <div className="payment-content-field">
                            <div className="security-fee-second">
                              <table className="table security-fee-left">
                                <thead>
                                  <tr className="sub-row pink-table">
                                    <th className="sub-topic">Bank Name</th>
                                    <th className="sub-num">
                                      {bankDetail ? bankDetail.bank_name : " "}
                                    </th>
                                  </tr>
                                  <tr className="sub-row pink-table">
                                    <th className="sub-topic">
                                      Bank Account Number
                                    </th>
                                    <th className="sub-num">
                                      {bankDetail
                                        ? bankDetail.account_number
                                        : ""}
                                    </th>
                                  </tr>
                                  <tr className="sub-row pink-table">
                                    <th className="sub-topic">
                                      Bank Account Name
                                    </th>
                                    <th className="sub-num">
                                      {bankDetail
                                        ? bankDetail.account_name
                                        : ""}
                                    </th>
                                  </tr>
                                  <tr className="sub-row pink-table">
                                    <th className="sub-topic">Branch</th>
                                    <th className="sub-num">
                                      {bankDetail ? bankDetail.branch : ""}
                                    </th>
                                  </tr>
                                </thead>
                              </table>
                            </div>
                            <div className="security-fee-third">
                              <table className="table security-fee-right">
                                <thead>
                                  <tr className=" sub-row last pink-table">
                                    <th>Deposit Amount</th>
                                    <th className="sub-num-last">
                                      NPR.
                                      {bidFee}
                                    </th>
                                  </tr>
                                </thead>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Note>
                    The Bid Fee is not required for this notice. Please proceed with next step.
                  </Note>
                )}
              </>
            )}
            <div className="border-line"></div>
            <div className="d-flex"></div>
          </div>

          {bankDetail &&
            parseFloat(bankDetail.bid_bond_security_amount as string) > 0 && (
              <div className="pay-security-fee">
                <div className="detail-content">
                  <p>2.Security Fee </p>
                  <div className="d-flex">
                    <div className="upload-venture">
                      <div className="drop-icon col-md-10">
                        <DropZoneBox
                          name="bidFee.voucher"
                          placeholder="Upload Bank Voucher or Bank Guarantee"
                          label="Upload the cash deposit voucher to pay security fee"
                        />
                        {errors.bidFee?.voucher && (
                          <span className="error">
                            {errors.bidFee?.voucher}
                          </span>
                        )}
                        {resDocument?.fee?.voucher && (
                          <UploadedFile url={resDocument?.fee?.voucher} />
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="content-left second-content">
                        <p>
                          Deposit the mentioned security fee in bank information
                          provided below.
                        </p>
                      </div>
                      <div className="payement-last-fee ">
                        <div className="payment-content-field">
                          <div className="security-fee-second">
                            <table className="table security-fee-left">
                              <thead>
                                <tr className="sub-row pink-table">
                                  <th className="sub-topic">Bank Name</th>
                                  <th className="sub-num">
                                    {bankDetail ? bankDetail.bank_name : " "}
                                  </th>
                                </tr>
                                <tr className="sub-row pink-table">
                                  <th className="sub-topic">
                                    Bank Account Number
                                  </th>
                                  <th className="sub-num">
                                    {bankDetail
                                      ? bankDetail.account_number
                                      : ""}
                                  </th>
                                </tr>
                                <tr className="sub-row pink-table">
                                  <th className="sub-topic">
                                    Bank Account Name
                                  </th>
                                  <th className="sub-num">
                                    {bankDetail ? bankDetail.account_name : ""}
                                  </th>
                                </tr>
                                <tr className="sub-row pink-table">
                                  <th className="sub-topic">Branch</th>
                                  <th className="sub-num">
                                    {bankDetail ? bankDetail.branch : ""}
                                  </th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                          <div className="security-fee-third">
                            <table className="table security-fee-right">
                              <thead>
                                <tr className=" sub-row last pink-table">
                                  <th>Deposit Amount</th>
                                  <th className="sub-num-last">
                                    NPR.
                                    {bankDetail &&
                                      bankDetail.bid_bond_security_amount}
                                  </th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                        </div>
                      </div>
                      <p>Or</p>
                      <div className="security-fee-third">
                        <table className="table security-fee-right">
                          <thead>
                            <tr className=" sub-row last pink-table">
                              <th>Bank Guarantee Validity</th>
                              <th className="sub-num-last">
                                {bankDetail ? bankDetail.bid_bond_validity : ""}
                                <span> Days </span>
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
        <div className="position">
          <button
            type="button"
            className="save-btn"
            onClick={() => {
              setPage(BidDetailTabs.DOCUMENT);
            }}
          >
            Back
          </button>
          <div className="save-section">
            <button
              disabled={isSubmitting}
              className="transparent-btn"
              type="submit"
            >
              {isSubmitting ? (
                <CustomLoader
                  color="#0052a7"
                  type="TailSpin"
                  height={20}
                  width={105}
                />
              ) : (
                <span>{isNoticeStandingList ? "Save" : "Save & Next"}</span>
              )}
            </button>
            {isNoticeStandingList && (
              <button
                type="button"
                className="save-btn btn-edit"
                onClick={() => {
                  documentAndFeeSubmission(values);
                }}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div >
    </>
  );
};
