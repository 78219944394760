export const Municipalities = [
  {
    label: "Kathmandu",
    value: "Kathmandu",
  },
  {
    label: "Pokhara",
    value: "Pokhara",
  },
  {
    label: "Lalitpur",
    value: "Lalitpur",
  },
  {
    label: "Bharatpur",
    value: "Bharatpur",
  },
  {
    label: "Birgunj",
    value: "Birgunj",
  },
  {
    label: "Biratnagar",
    value: "Biratnagar",
  },
  {
    label: "Mechinagar",
    value: "Mechinagar",
  },
  {
    label: "Budhanilkantha",
    value: "Budhanilkantha",
  },
  {
    label: "Gokarneshwar",
    value: "Gokarneshwar",
  },
  {
    label: "Bhimdatta",
    value: "Bhimdatta",
  },
  {
    label: "Birendranagar",
    value: "Birendranagar",
  },
  {
    label: "Tilottama",
    value: "Tilottama",
  },
  {
    label: "Tokha",
    value: "Tokha",
  },
  {
    label: "Lahan",
    value: "Lahan",
  },
  {
    label: "Triyuga",
    value: "Triyuga",
  },
  {
    label: "Chandragiri",
    value: "Chandragiri",
  },
  {
    label: "Madhyapur Thimi",
    value: "Madhyapur Thimi",
  },
  {
    label: "Siraha",
    value: "Siraha",
  },
  {
    label: "Birtamod",
    value: "Birtamod",
  },
  {
    label: "Bhaktapur",
    value: "Bhaktapur",
  },
  {
    label: "Tarakeshwar",
    value: "Tarakeshwar",
  },
  {
    label: "Tikapur",
    value: "Tikapur",
  },
  {
    label: "Sundar Haraincha",
    value: "Sundar Haraincha",
  },
  {
    label: "Suryabinayak",
    value: "Suryabinayak",
  },
  {
    label: "Godawari",
    value: "Godawari",
  },
  {
    label: "Godawari, Seti",
    value: "Godawari, Seti",
  },
  {
    label: "Barahachhetra",
    value: "Barahachhetra",
  },
  {
    label: "Kapilvastu",
    value: "Kapilvastu",
  },
  {
    label: "Ghodaghodi",
    value: "Ghodaghodi",
  },
  {
    label: "Lamki Chuha",
    value: "Lamki Chuha",
  },
  {
    label: "Banganga",
    value: "Banganga",
  },
  {
    label: "Damak",
    value: "Damak",
  },
  {
    label: "Lumbini Sanskritik",
    value: "Lumbini Sanskritik",
  },
  {
    label: "Chandrapur",
    value: "Chandrapur",
  },
  {
    label: "Kohalpur",
    value: "Kohalpur",
  },
  {
    label: "Vyas",
    value: "Vyas",
  },
  {
    label: "Ratnanagar",
    value: "Ratnanagar",
  },
  {
    label: "Barahathwa",
    value: "Barahathwa",
  },
  {
    label: "Rajbiraj",
    value: "Rajbiraj",
  },
  {
    label: "Barbardiya",
    value: "Barbardiya",
  },
  {
    label: "Shivaraj",
    value: "Shivaraj",
  },
  {
    label: "Gulariya",
    value: "Gulariya",
  },
  {
    label: "Gaushala",
    value: "Gaushala",
  },
  {
    label: "Bardibas",
    value: "Bardibas",
  },
  {
    label: "Belbari",
    value: "Belbari",
  },
  {
    label: "Kirtipur",
    value: "Kirtipur",
  },
  {
    label: "Bhadrapur",
    value: "Bhadrapur",
  },
  {
    label: "Nagarjun",
    value: "Nagarjun",
  },
  {
    label: "Dudhauli",
    value: "Dudhauli",
  },
  {
    label: "Kamalamai",
    value: "Kamalamai",
  },
  {
    label: "Buddhabhumi",
    value: "Buddhabhumi",
  },
  {
    label: "Shivasatakshi",
    value: "Shivasatakshi",
  },
  {
    label: "Inaruwa",
    value: "Inaruwa",
  },
  {
    label: "Siddharthanagar",
    value: "Siddharthanagar",
  },
  {
    label: "Pathari-Shanischare",
    value: "Pathari-Shanischare",
  },
  {
    label: "Kawasoti",
    value: "Kawasoti",
  },
  {
    label: "Krishnanagar",
    value: "Krishnanagar",
  },
  {
    label: "Mahalaxmi",
    value: "Mahalaxmi",
  },
  {
    label: "Kageshwari-Manohara",
    value: "Kageshwari-Manohara",
  },
  {
    label: "Arjundhara",
    value: "Arjundhara",
  },
  {
    label: "Ishwarpur",
    value: "Ishwarpur",
  },
  {
    label: "Rajapur",
    value: "Rajapur",
  },
  {
    label: "Ramgram",
    value: "Ramgram",
  },
  {
    label: "Lalbandi",
    value: "Lalbandi",
  },
  {
    label: "Gaindakot",
    value: "Gaindakot",
  },
  {
    label: "Jaleshwar",
    value: "Jaleshwar",
  },
  {
    label: "Nilkantha",
    value: "Nilkantha",
  },
  {
    label: "Baglung",
    value: "Baglung",
  },
  {
    label: "Rapti",
    value: "Rapti",
  },
  {
    label: "Suryodaya",
    value: "Suryodaya",
  },
  {
    label: "Krishnapur",
    value: "Krishnapur",
  },
  {
    label: "Duhabi",
    value: "Duhabi",
  },
  {
    label: "Katari",
    value: "Katari",
  },
  {
    label: "Khairhani",
    value: "Khairhani",
  },
  {
    label: "Bansgadhi",
    value: "Bansgadhi",
  },
  {
    label: "Sainamaina",
    value: "Sainamaina",
  },
  {
    label: "Banepa",
    value: "Banepa",
  },
  {
    label: "Changunarayan",
    value: "Changunarayan",
  },
  {
    label: "Sunwal",
    value: "Sunwal",
  },
  {
    label: "Bardghat",
    value: "Bardghat",
  },
  {
    label: "Ratuwamai",
    value: "Ratuwamai",
  },
  {
    label: "Gauriganga",
    value: "Gauriganga",
  },
  {
    label: "Maharajganj",
    value: "Maharajganj",
  },
  {
    label: "Urlabari",
    value: "Urlabari",
  },
  {
    label: "Mahagadhimai",
    value: "Mahagadhimai",
  },
  {
    label: "Bidur",
    value: "Bidur",
  },
  {
    label: "Madhyabindu",
    value: "Madhyabindu",
  },
  {
    label: "Punarbas",
    value: "Punarbas",
  },
  {
    label: "Belauri",
    value: "Belauri",
  },
  {
    label: "Devdaha",
    value: "Devdaha",
  },
  {
    label: "Gauradaha",
    value: "Gauradaha",
  },
  {
    label: "Rangeli",
    value: "Rangeli",
  },
  {
    label: "Bhajani",
    value: "Bhajani",
  },
  {
    label: "Ramdhuni",
    value: "Ramdhuni",
  },
  {
    label: "Waling",
    value: "Waling",
  },
  {
    label: "Golbazar",
    value: "Golbazar",
  },
  {
    label: "Sunawarshi",
    value: "Sunawarshi",
  },
  {
    label: "Garuda",
    value: "Garuda",
  },
  {
    label: "Tansen",
    value: "Tansen",
  },
  {
    label: "Mirchaiya",
    value: "Mirchaiya",
  },
  {
    label: "Simraungadh",
    value: "Simraungadh",
  },
  {
    label: "Manara Shisawa",
    value: "Manara Shisawa",
  },
  {
    label: "Bedkot",
    value: "Bedkot",
  },
  {
    label: "Kalyanpur",
    value: "Kalyanpur",
  },
  {
    label: "Gorkha",
    value: "Gorkha",
  },
  {
    label: "Phidim",
    value: "Phidim",
  },
  {
    label: "Chaudandigadhi",
    value: "Chaudandigadhi",
  },
  {
    label: "Ilam",
    value: "Ilam",
  },
  {
    label: "Shuklagandaki",
    value: "Shuklagandaki",
  },
  {
    label: "Godaita",
    value: "Godaita",
  },
  {
    label: "Lamahi",
    value: "Lamahi",
  },
  {
    label: "Dhangadimai",
    value: "Dhangadimai",
  },
  {
    label: "Diktel–Rupakot–Majuwagadhi",
    value: "Diktel–Rupakot–Majuwagadhi",
  },
  {
    label: "Shuklaphanta",
    value: "Shuklaphanta",
  },
  {
    label: "Bhangaha",
    value: "Bhangaha",
  },
  {
    label: "Paunauti",
    value: "Paunauti",
  },
  {
    label: "Gujara",
    value: "Gujara",
  },
  {
    label: "Malangwa",
    value: "Malangwa",
  },
  {
    label: "Chautara Sangachokgadhi",
    value: "Chautara Sangachokgadhi",
  },
  {
    label: "Madhuwan",
    value: "Madhuwan",
  },
  {
    label: "Sabaila",
    value: "Sabaila",
  },
  {
    label: "Bhanu",
    value: "Bhanu",
  },
  {
    label: "Hanumannagar Kankalini",
    value: "Hanumannagar Kankalini",
  },
  {
    label: "Dhanushadham",
    value: "Dhanushadham",
  },
  {
    label: "Manthali",
    value: "Manthali",
  },
  {
    label: "Khadak",
    value: "Khadak",
  },
  {
    label: "Melamchi",
    value: "Melamchi",
  },
  {
    label: "Balara",
    value: "Balara",
  },
  {
    label: "Mithila",
    value: "Mithila",
  },
  {
    label: "Putalibazar",
    value: "Putalibazar",
  },
  {
    label: "Dakneshwari",
    value: "Dakneshwari",
  },
  {
    label: "Thakurbaba",
    value: "Thakurbaba",
  },
  {
    label: "Surunga",
    value: "Surunga",
  },
  {
    label: "Hariwan",
    value: "Hariwan",
  },
  {
    label: "Gurbhakot",
    value: "Gurbhakot",
  },
  {
    label: "Sitganga",
    value: "Sitganga",
  },
  {
    label: "Bodebarsain",
    value: "Bodebarsain",
  },
  {
    label: "Kolhabi",
    value: "Kolhabi",
  },
  {
    label: "Shahidnagar",
    value: "Shahidnagar",
  },
  {
    label: "Brindaban",
    value: "Brindaban",
  },
  {
    label: "Devchuli",
    value: "Devchuli",
  },
  {
    label: "Kshireshwarnath",
    value: "Kshireshwarnath",
  },
  {
    label: "Belaka",
    value: "Belaka",
  },
  {
    label: "Balawa",
    value: "Balawa",
  },
  {
    label: "Kabilasi",
    value: "Kabilasi",
  },
  {
    label: "Kalika",
    value: "Kalika",
  },
  {
    label: "Thaha",
    value: "Thaha",
  },
  {
    label: "Dullu",
    value: "Dullu",
  },
  {
    label: "Ishnath",
    value: "Ishnath",
  },
  {
    label: "Bheriganga",
    value: "Bheriganga",
  },
  {
    label: "Sandhikharka",
    value: "Sandhikharka",
  },
  {
    label: "Rajpur",
    value: "Rajpur",
  },
  {
    label: "Gadhimai",
    value: "Gadhimai",
  },
  {
    label: "Bagmati",
    value: "Bagmati",
  },
  {
    label: "Kankai",
    value: "Kankai",
  },
  {
    label: "Belkotgadhi",
    value: "Belkotgadhi",
  },
  {
    label: "Bahudarmai",
    value: "Bahudarmai",
  },
  {
    label: "Kushma",
    value: "Kushma",
  },
  {
    label: "Loharpatti",
    value: "Loharpatti",
  },
  {
    label: "Besisahar",
    value: "Besisahar",
  },
  {
    label: "Mahakali",
    value: "Mahakali",
  },
  {
    label: "Purchaundi",
    value: "Purchaundi",
  },
  {
    label: "Hansapur",
    value: "Hansapur",
  },
  {
    label: "Kamala",
    value: "Kamala",
  },
  {
    label: "Pyuthan",
    value: "Pyuthan",
  },
  {
    label: "Katahariya",
    value: "Katahariya",
  },
  {
    label: "Palungtar",
    value: "Palungtar",
  },
  {
    label: "Parsagadhi",
    value: "Parsagadhi",
  },
  {
    label: "Shambhunath",
    value: "Shambhunath",
  },
  {
    label: "Panchkhal",
    value: "Panchkhal",
  },
  {
    label: "Madi",
    value: "Madi",
  },
  {
    label: "Sukhipur",
    value: "Sukhipur",
  },
  {
    label: "Paroha",
    value: "Paroha",
  },
  {
    label: "Haripur",
    value: "Haripur",
  },
  {
    label: "Ganeshman Charnath",
    value: "Ganeshman Charnath",
  },
  {
    label: "Galyang",
    value: "Galyang",
  },
  {
    label: "Dhankuta",
    value: "Dhankuta",
  },
  {
    label: "Phatuwa Bijayapur",
    value: "Phatuwa Bijayapur",
  },
  {
    label: "Baudhimai",
    value: "Baudhimai",
  },
  {
    label: "Bangad Kupinde",
    value: "Bangad Kupinde",
  },
  {
    label: "Haripurwa",
    value: "Haripurwa",
  },
  {
    label: "Rampur",
    value: "Rampur",
  },
  {
    label: "Chhedagad",
    value: "Chhedagad",
  },
  {
    label: "Kanchanrup",
    value: "Kanchanrup",
  },
  {
    label: "Parashuram",
    value: "Parashuram",
  },
  {
    label: "Nagarain",
    value: "Nagarain",
  },
  {
    label: "Dasharathchand",
    value: "Dasharathchand",
  },
  {
    label: "Nijgadh",
    value: "Nijgadh",
  },
  {
    label: "Madhav Narayan",
    value: "Madhav Narayan",
  },
  {
    label: "Gaur",
    value: "Gaur",
  },
  {
    label: "Pachrauta",
    value: "Pachrauta",
  },
  {
    label: "Bagchaur",
    value: "Bagchaur",
  },
  {
    label: "Sanphebagar",
    value: "Sanphebagar",
  },
  {
    label: "Shaarda",
    value: "Shaarda",
  },
  {
    label: "Aathabiskot",
    value: "Aathabiskot",
  },
  {
    label: "Mithila Bihari",
    value: "Mithila Bihari",
  },
  {
    label: "Bheri",
    value: "Bheri",
  },
  {
    label: "Beni",
    value: "Beni",
  },
  {
    label: "Bungal",
    value: "Bungal",
  },
  {
    label: "Galkot",
    value: "Galkot",
  },
  {
    label: "Dipayal Silgadhi",
    value: "Dipayal Silgadhi",
  },
  {
    label: "Musikot",
    value: "Musikot",
  },
  {
    label: "Deumai",
    value: "Deumai",
  },
  {
    label: "Pokhariya",
    value: "Pokhariya",
  },
  {
    label: "Musikot",
    value: "Musikot",
  },
  {
    label: "Rolpa",
    value: "Rolpa",
  },
  {
    label: "Mandandeupur",
    value: "Mandandeupur",
  },
  {
    label: "Bhumikasthan",
    value: "Bhumikasthan",
  },
  {
    label: "Mai",
    value: "Mai",
  },
  {
    label: "Resunga",
    value: "Resunga",
  },
  {
    label: "Mangalsen",
    value: "Mangalsen",
  },
  {
    label: "Bideha",
    value: "Bideha",
  },
  {
    label: "Panchapuri",
    value: "Panchapuri",
  },
  {
    label: "Dhulikhel",
    value: "Dhulikhel",
  },
  {
    label: "Dewahi Gonahi",
    value: "Dewahi Gonahi",
  },
  {
    label: "Letang Bhogateni",
    value: "Letang Bhogateni",
  },
  {
    label: "Shikhar",
    value: "Shikhar",
  },
  {
    label: "Aurahi",
    value: "Aurahi",
  },
  {
    label: "Shadanand",
    value: "Shadanand",
  },
  {
    label: "Bhimeshwar",
    value: "Bhimeshwar",
  },
  {
    label: "Jaimini",
    value: "Jaimini",
  },
  {
    label: "Bhimad",
    value: "Bhimad",
  },
  {
    label: "Rajdevi",
    value: "Rajdevi",
  },
  {
    label: "Khandbari",
    value: "Khandbari",
  },
  {
    label: "Dhunibeshi",
    value: "Dhunibeshi",
  },
  {
    label: "Matihani",
    value: "Matihani",
  },
  {
    label: "Karjanha",
    value: "Karjanha",
  },
  {
    label: "Swargadwari",
    value: "Swargadwari",
  },
  {
    label: "Patan",
    value: "Patan",
  },
  {
    label: "Lekbeshi",
    value: "Lekbeshi",
  },
  {
    label: "Ramgopalpur",
    value: "Ramgopalpur",
  },
  {
    label: "Halesi Tuwachung",
    value: "Halesi Tuwachung",
  },
  {
    label: "Namobuddha",
    value: "Namobuddha",
  },
  {
    label: "Aathabis",
    value: "Aathabis",
  },
  {
    label: "Ramechhap",
    value: "Ramechhap",
  },
  {
    label: "Siddhicharan",
    value: "Siddhicharan",
  },
  {
    label: "Panchadewal Binayak",
    value: "Panchadewal Binayak",
  },
  {
    label: "Chaurjahari",
    value: "Chaurjahari",
  },
  {
    label: "Chainpur",
    value: "Chainpur",
  },
  {
    label: "Bhojpur",
    value: "Bhojpur",
  },
  {
    label: "Narayan",
    value: "Narayan",
  },
  {
    label: "Sundarbazar",
    value: "Sundarbazar",
  },
  {
    label: "Barhabise",
    value: "Barhabise",
  },
  {
    label: "Maulapur",
    value: "Maulapur",
  },
  {
    label: "Taplejung(Phungling)",
    value: "Taplejung(Phungling)",
  },
  {
    label: "Dhorpatan",
    value: "Dhorpatan",
  },
  {
    label: "Chamunda Bindrasaini",
    value: "Chamunda Bindrasaini",
  },
  {
    label: "Chapakot",
    value: "Chapakot",
  },
  {
    label: "Nalgad",
    value: "Nalgad",
  },
  {
    label: "Bhirkot",
    value: "Bhirkot",
  },
  {
    label: "Shankharapur",
    value: "Shankharapur",
  },
  {
    label: "Mahalaxmi",
    value: "Mahalaxmi",
  },
  {
    label: "Phalewas",
    value: "Phalewas",
  },
  {
    label: "Dakshinkali",
    value: "Dakshinkali",
  },
  {
    label: "Kamalbazar",
    value: "Kamalbazar",
  },
  {
    label: "Madhya Nepal",
    value: "Madhya Nepal",
  },
  {
    label: "Melauli",
    value: "Melauli",
  },
  {
    label: "Jaya Prithvi",
    value: "Jaya Prithvi",
  },
  {
    label: "Pakhribas",
    value: "Pakhribas",
  },
  {
    label: "Shailyashikhar",
    value: "Shailyashikhar",
  },
  {
    label: "Budhiganga",
    value: "Budhiganga",
  },
  {
    label: "Amargadhi",
    value: "Amargadhi",
  },
  {
    label: "Mahakali",
    value: "Mahakali",
  },
  {
    label: "Saptakoshi",
    value: "Saptakoshi",
  },
  {
    label: "Solu Dudhkunda",
    value: "Solu Dudhkunda",
  },
  {
    label: "Khandachakra",
    value: "Khandachakra",
  },
  {
    label: "Chhayanath Rara",
    value: "Chhayanath Rara",
  },
  {
    label: "Myanglung",
    value: "Myanglung",
  },
  {
    label: "Chandannath",
    value: "Chandannath",
  },
  {
    label: "Budhinanda",
    value: "Budhinanda",
  },
  {
    label: "Rainas",
    value: "Rainas",
  },
  {
    label: "Tribeni",
    value: "Tribeni",
  },
  {
    label: "Dharmadevi",
    value: "Dharmadevi",
  },
  {
    label: "Panchkhapan",
    value: "Panchkhapan",
  },
  {
    label: "Laligurans",
    value: "Laligurans",
  },
  {
    label: "Badimalika",
    value: "Badimalika",
  },
  {
    label: "Raskot",
    value: "Raskot",
  },
  {
    label: "Tilagupha",
    value: "Tilagupha",
  },
  {
    label: "Jiri",
    value: "Jiri",
  },
  {
    label: "Madi",
    value: "Madi",
  },
  {
    label: "Tripura Sundari",
    value: "Tripura Sundari",
  },
  {
    label: "Thuli Bheri",
    value: "Thuli Bheri",
  },
  {
    label: "Hatuwagadhi",
    value: "Hatuwagadhi",
  },
  {
    label: "Ramprasad Rai",
    value: "Ramprasad Rai",
  },
  {
    label: "Aamchok",
    value: "Aamchok",
  },
  {
    label: "Tyamke Maiyunm",
    value: "Tyamke Maiyunm",
  },
  {
    label: "Arun",
    value: "Arun",
  },
  {
    label: "Pauwadungma",
    value: "Pauwadungma",
  },
  {
    label: "Salpasilichho",
    value: "Salpasilichho",
  },
  {
    label: "Sangurigadhi",
    value: "Sangurigadhi",
  },
  {
    label: "Chaubise",
    value: "Chaubise",
  },
  {
    label: "Khalsa Chhintang Sahidbhumi",
    value: "Khalsa Chhintang Sahidbhumi",
  },
  {
    label: "Chhathar Jorpati",
    value: "Chhathar Jorpati",
  },
  {
    label: "Phakphokthum",
    value: "Phakphokthum",
  },
  {
    label: "Mai Jogmai",
    value: "Mai Jogmai",
  },
  {
    label: "Chulachuli",
    value: "Chulachuli",
  },
  {
    label: "Rong",
    value: "Rong",
  },
  {
    label: "Mangsebung",
    value: "Mangsebung",
  },
  {
    label: "Sandakpur",
    value: "Sandakpur",
  },
  {
    label: "Kamal",
    value: "Kamal",
  },
  {
    label: "Buddha Shanti",
    value: "Buddha Shanti",
  },
  {
    label: "Kachankawal",
    value: "Kachankawal",
  },
  {
    label: "Jhapa",
    value: "Jhapa",
  },
  {
    label: "Barhadashi",
    value: "Barhadashi",
  },
  {
    label: "Gaurigunj",
    value: "Gaurigunj",
  },
  {
    label: "Haldibari",
    value: "Haldibari",
  },
  {
    label: "Khotehang",
    value: "Khotehang",
  },
  {
    label: "Diprung",
    value: "Diprung",
  },
  {
    label: "Aiselukharka",
    value: "Aiselukharka",
  },
  {
    label: "Jantedhunga",
    value: "Jantedhunga",
  },
  {
    label: "Kepilasgadhi",
    value: "Kepilasgadhi",
  },
  {
    label: "Barahpokhari",
    value: "Barahpokhari",
  },
  {
    label: "Lamidanda",
    value: "Lamidanda",
  },
  {
    label: "Sakela",
    value: "Sakela",
  },
  {
    label: "Jahada",
    value: "Jahada",
  },
  {
    label: "Budi Ganga",
    value: "Budi Ganga",
  },
  {
    label: "Katahari",
    value: "Katahari",
  },
  {
    label: "Dhanpalthan",
    value: "Dhanpalthan",
  },
  {
    label: "Kanepokhari",
    value: "Kanepokhari",
  },
  {
    label: "Gramthan",
    value: "Gramthan",
  },
  {
    label: "Kerabari",
    value: "Kerabari",
  },
  {
    label: "Miklajung",
    value: "Miklajung",
  },
  {
    label: "Manebhanjyang",
    value: "Manebhanjyang",
  },
  {
    label: "Champadevi",
    value: "Champadevi",
  },
  {
    label: "Sunkoshi",
    value: "Sunkoshi",
  },
  {
    label: "Molung",
    value: "Molung",
  },
  {
    label: "Chisankhugadhi",
    value: "Chisankhugadhi",
  },
  {
    label: "Khiji Demba",
    value: "Khiji Demba",
  },
  {
    label: "Likhu",
    value: "Likhu",
  },
  {
    label: "Miklajung",
    value: "Miklajung",
  },
  {
    label: "Phalgunanda",
    value: "Phalgunanda",
  },
  {
    label: "Hilihang",
    value: "Hilihang",
  },
  {
    label: "Phalelung",
    value: "Phalelung",
  },
  {
    label: "Yangwarak",
    value: "Yangwarak",
  },
  {
    label: "Kummayak",
    value: "Kummayak",
  },
  {
    label: "Tumbewa",
    value: "Tumbewa",
  },
  {
    label: "Makalu",
    value: "Makalu",
  },
  {
    label: "Silichong",
    value: "Silichong",
  },
  {
    label: "Sabhapokhari",
    value: "Sabhapokhari",
  },
  {
    label: "Chichila",
    value: "Chichila",
  },
  {
    label: "Bhot Khola",
    value: "Bhot Khola",
  },
  {
    label: "Dudhakaushika",
    value: "Dudhakaushika",
  },
  {
    label: "Necha Salyan",
    value: "Necha Salyan",
  },
  {
    label: "Dudhkoshi",
    value: "Dudhkoshi",
  },
  {
    label: "Maha Kulung",
    value: "Maha Kulung",
  },
  {
    label: "Sotang",
    value: "Sotang",
  },
  {
    label: "Khumbu Pasang Lhamu",
    value: "Khumbu Pasang Lhamu",
  },
  {
    label: "Likhu Pike",
    value: "Likhu Pike",
  },
  {
    label: "Koshi",
    value: "Koshi",
  },
  {
    label: "Harinagara",
    value: "Harinagara",
  },
  {
    label: "Bhokraha",
    value: "Bhokraha",
  },
  {
    label: "Dewangunj",
    value: "Dewangunj",
  },
  {
    label: "Gadhi",
    value: "Gadhi",
  },
  {
    label: "Barju",
    value: "Barju",
  },
  {
    label: "Sirijangha",
    value: "Sirijangha",
  },
  {
    label: "Aathrai Triveni",
    value: "Aathrai Triveni",
  },
  {
    label: "Pathibhara Yangwarak",
    value: "Pathibhara Yangwarak",
  },
  {
    label: "Meringden",
    value: "Meringden",
  },
  {
    label: "Sidingwa",
    value: "Sidingwa",
  },
  {
    label: "Phaktanglung",
    value: "Phaktanglung",
  },
  {
    label: "Maiwa Khola",
    value: "Maiwa Khola",
  },
  {
    label: "Mikwa Khola",
    value: "Mikwa Khola",
  },
  {
    label: "Aathrai",
    value: "Aathrai",
  },
  {
    label: "Phedap",
    value: "Phedap",
  },
  {
    label: "Chhathar",
    value: "Chhathar",
  },
  {
    label: "Menchayayem",
    value: "Menchayayem",
  },
  {
    label: "Udayapurgadhi",
    value: "Udayapurgadhi",
  },
  {
    label: "Rautamai",
    value: "Rautamai",
  },
  {
    label: "Tapli",
    value: "Tapli",
  },
  {
    label: "Limchungbung",
    value: "Limchungbung",
  },
  {
    label: "Subarna",
    value: "Subarna",
  },
  {
    label: "Adarsha Kotwal",
    value: "Adarsha Kotwal",
  },
  {
    label: "Baragadhi",
    value: "Baragadhi",
  },
  {
    label: "Pheta",
    value: "Pheta",
  },
  {
    label: "Karaiyamai",
    value: "Karaiyamai",
  },
  {
    label: "Prasauni",
    value: "Prasauni",
  },
  {
    label: "Bishrampur",
    value: "Bishrampur",
  },
  {
    label: "Devtal",
    value: "Devtal",
  },
  {
    label: "Parawanipur",
    value: "Parawanipur",
  },
  {
    label: "Laksminiya",
    value: "Laksminiya",
  },
  {
    label: "Mukhiyapatti Musaharmiya",
    value: "Mukhiyapatti Musaharmiya",
  },
  {
    label: "Janak Nandini",
    value: "Janak Nandini",
  },
  {
    label: "Aaurahi",
    value: "Aaurahi",
  },
  {
    label: "Bateshwar",
    value: "Bateshwar",
  },
  {
    label: "Dhanauji",
    value: "Dhanauji",
  },
  {
    label: "Sonama",
    value: "Sonama",
  },
  {
    label: "Pipara",
    value: "Pipara",
  },
  {
    label: "Samsi",
    value: "Samsi",
  },
  {
    label: "Ekdara",
    value: "Ekdara",
  },
  {
    label: "Mahottari",
    value: "Mahottari",
  },
  {
    label: "Sakhuwa Prasauni",
    value: "Sakhuwa Prasauni",
  },
  {
    label: "Jagarnathpur",
    value: "Jagarnathpur",
  },
  {
    label: "Chhipaharmai",
    value: "Chhipaharmai",
  },
  {
    label: "Bindabasini",
    value: "Bindabasini",
  },
  {
    label: "Paterwa Sugauli",
    value: "Paterwa Sugauli",
  },
  {
    label: "Jira Bhavani",
    value: "Jira Bhavani",
  },
  {
    label: "Kalikamai",
    value: "Kalikamai",
  },
  {
    label: "Pakaha Mainpur",
    value: "Pakaha Mainpur",
  },
  {
    label: "Thori",
    value: "Thori",
  },
  {
    label: "Dhobini",
    value: "Dhobini",
  },
  {
    label: "Durga Bhagawati",
    value: "Durga Bhagawati",
  },
  {
    label: "Yamunamai",
    value: "Yamunamai",
  },
  {
    label: "Tilathi Koiladi",
    value: "Tilathi Koiladi",
  },
  {
    label: "Belhi Chapena",
    value: "Belhi Chapena",
  },
  {
    label: "Chhinnamasta",
    value: "Chhinnamasta",
  },
  {
    label: "Mahadeva",
    value: "Mahadeva",
  },
  {
    label: "Aagnisaira Krishnasawaran",
    value: "Aagnisaira Krishnasawaran",
  },
  {
    label: "Rupani",
    value: "Rupani",
  },
  {
    label: "Balan-Bihul",
    value: "Balan-Bihul",
  },
  {
    label: "Bishnupur",
    value: "Bishnupur",
  },
  {
    label: "Tirhut",
    value: "Tirhut",
  },
  {
    label: "Chandranagar",
    value: "Chandranagar",
  },
  {
    label: "Bramhapuri",
    value: "Bramhapuri",
  },
  {
    label: "Ramnagar",
    value: "Ramnagar",
  },
  {
    label: "Chakraghatta",
    value: "Chakraghatta",
  },
  {
    label: "Kaudena",
    value: "Kaudena",
  },
  {
    label: "Dhankaul",
    value: "Dhankaul",
  },
  {
    label: "Bishnu",
    value: "Bishnu",
  },
  {
    label: "Basbariya",
    value: "Basbariya",
  },
  {
    label: "Parsa",
    value: "Parsa",
  },
  {
    label: "Laksmipur Patari",
    value: "Laksmipur Patari",
  },
  {
    label: "Bariyarpatti",
    value: "Bariyarpatti",
  },
  {
    label: "Aaurahi",
    value: "Aaurahi",
  },
  {
    label: "Arnama",
    value: "Arnama",
  },
  {
    label: "Bhagawanpur",
    value: "Bhagawanpur",
  },
  {
    label: "Naraha",
    value: "Naraha",
  },
  {
    label: "Nawarajpur",
    value: "Nawarajpur",
  },
  {
    label: "Sakhuwanankarkatti",
    value: "Sakhuwanankarkatti",
  },
  {
    label: "Bishnupur",
    value: "Bishnupur",
  },
  {
    label: "Ichchhakamana",
    value: "Ichchhakamana",
  },
  {
    label: "Thakre",
    value: "Thakre",
  },
  {
    label: "Benighat Rorang",
    value: "Benighat Rorang",
  },
  {
    label: "Galchhi",
    value: "Galchhi",
  },
  {
    label: "Gajuri",
    value: "Gajuri",
  },
  {
    label: "Jwalamukhi",
    value: "Jwalamukhi",
  },
  {
    label: "Siddhalekh",
    value: "Siddhalekh",
  },
  {
    label: "Tripura Sundari",
    value: "Tripura Sundari",
  },
  {
    label: "Gangajamuna",
    value: "Gangajamuna",
  },
  {
    label: "Netrawati Dabjong",
    value: "Netrawati Dabjong",
  },
  {
    label: "Khaniyabas",
    value: "Khaniyabas",
  },
  {
    label: "Ruby Valley",
    value: "Ruby Valley",
  },
  {
    label: "Kalinchok",
    value: "Kalinchok",
  },
  {
    label: "Melung",
    value: "Melung",
  },
  {
    label: "Shailung",
    value: "Shailung",
  },
  {
    label: "Baiteshwar",
    value: "Baiteshwar",
  },
  {
    label: "Tamakoshi",
    value: "Tamakoshi",
  },
  {
    label: "Bigu",
    value: "Bigu",
  },
  {
    label: "Gaurishankar",
    value: "Gaurishankar",
  },
  {
    label: "Roshi",
    value: "Roshi",
  },
  {
    label: "Temal",
    value: "Temal",
  },
  {
    label: "Chaunri Deurali",
    value: "Chaunri Deurali",
  },
  {
    label: "Bhumlu",
    value: "Bhumlu",
  },
  {
    label: "Mahabharat",
    value: "Mahabharat",
  },
  {
    label: "Bethanchok",
    value: "Bethanchok",
  },
  {
    label: "Khanikhola",
    value: "Khanikhola",
  },
  {
    label: "Bagmati",
    value: "Bagmati",
  },
  {
    label: "Konjyosom",
    value: "Konjyosom",
  },
  {
    label: "Mahankal",
    value: "Mahankal",
  },
  {
    label: "Bakaiya",
    value: "Bakaiya",
  },
  {
    label: "Manhari",
    value: "Manhari",
  },
  {
    label: "Bagmati",
    value: "Bagmati",
  },
  {
    label: "Raksirang",
    value: "Raksirang",
  },
  {
    label: "Makawanpurgadhi",
    value: "Makawanpurgadhi",
  },
  {
    label: "Kailash",
    value: "Kailash",
  },
  {
    label: "Bhimphedi",
    value: "Bhimphedi",
  },
  {
    label: "Indrasarowar",
    value: "Indrasarowar",
  },
  {
    label: "Kakani",
    value: "Kakani",
  },
  {
    label: "Dupcheshwar",
    value: "Dupcheshwar",
  },
  {
    label: "Shivapuri",
    value: "Shivapuri",
  },
  {
    label: "Tadi",
    value: "Tadi",
  },
  {
    label: "Likhu",
    value: "Likhu",
  },
  {
    label: "Suryagadhi",
    value: "Suryagadhi",
  },
  {
    label: "Panchakanya",
    value: "Panchakanya",
  },
  {
    label: "Tarkeshwar",
    value: "Tarkeshwar",
  },
  {
    label: "Kispang",
    value: "Kispang",
  },
  {
    label: "Myagang",
    value: "Myagang",
  },
  {
    label: "Khandadevi",
    value: "Khandadevi",
  },
  {
    label: "Likhu Tamakoshi",
    value: "Likhu Tamakoshi",
  },
  {
    label: "Doramba",
    value: "Doramba",
  },
  {
    label: "Gokulganga",
    value: "Gokulganga",
  },
  {
    label: "Sunapati",
    value: "Sunapati",
  },
  {
    label: "Umakunda",
    value: "Umakunda",
  },
  {
    label: "Naukunda",
    value: "Naukunda",
  },
  {
    label: "Kalika",
    value: "Kalika",
  },
  {
    label: "Uttargaya",
    value: "Uttargaya",
  },
  {
    label: "Gosaikund",
    value: "Gosaikund",
  },
  {
    label: "Aamachodingmo",
    value: "Aamachodingmo",
  },
  {
    label: "Tinpatan",
    value: "Tinpatan",
  },
  {
    label: "Marin",
    value: "Marin",
  },
  {
    label: "Hariharpurgadhi",
    value: "Hariharpurgadhi",
  },
  {
    label: "Sunkoshi",
    value: "Sunkoshi",
  },
  {
    label: "Golanjor",
    value: "Golanjor",
  },
  {
    label: "Phikkal",
    value: "Phikkal",
  },
  {
    label: "Ghyanglekh",
    value: "Ghyanglekh",
  },
  {
    label: "Indrawati",
    value: "Indrawati",
  },
  {
    label: "Panchpokhari Thangpal",
    value: "Panchpokhari Thangpal",
  },
  {
    label: "Jugal",
    value: "Jugal",
  },
  {
    label: "Balephi",
    value: "Balephi",
  },
  {
    label: "Helambu",
    value: "Helambu",
  },
  {
    label: "Bhotekoshi",
    value: "Bhotekoshi",
  },
  {
    label: "Sunkoshi",
    value: "Sunkoshi",
  },
  {
    label: "Lisankhu Pakhar",
    value: "Lisankhu Pakhar",
  },
  {
    label: "Tripura Sundari",
    value: "Tripura Sundari",
  },
  {
    label: "Badigad",
    value: "Badigad",
  },
  {
    label: "Kathekhola",
    value: "Kathekhola",
  },
  {
    label: "Nisikhola",
    value: "Nisikhola",
  },
  {
    label: "Bareng",
    value: "Bareng",
  },
  {
    label: "Tarakhola",
    value: "Tarakhola",
  },
  {
    label: "Tamankhola",
    value: "Tamankhola",
  },
  {
    label: "Shahid Lakhan",
    value: "Shahid Lakhan",
  },
  {
    label: "Barpak Sulikot",
    value: "Barpak Sulikot",
  },
  {
    label: "Aarughat",
    value: "Aarughat",
  },
  {
    label: "Siranchok",
    value: "Siranchok",
  },
  {
    label: "Gandaki",
    value: "Gandaki",
  },
  {
    label: "Bhimsen Thapa",
    value: "Bhimsen Thapa",
  },
  {
    label: "Ajirkot",
    value: "Ajirkot",
  },
  {
    label: "Dharche",
    value: "Dharche",
  },
  {
    label: "Chum Nubri",
    value: "Chum Nubri",
  },
  {
    label: "Annapurna",
    value: "Annapurna",
  },
  {
    label: "Machhapuchhre",
    value: "Machhapuchhre",
  },
  {
    label: "Madi",
    value: "Madi",
  },
  {
    label: "Rupa",
    value: "Rupa",
  },
  {
    label: "Marsyangdi",
    value: "Marsyangdi",
  },
  {
    label: "Dordi",
    value: "Dordi",
  },
  {
    label: "Dudhpokhari",
    value: "Dudhpokhari",
  },
  {
    label: "Kwaholasothar",
    value: "Kwaholasothar",
  },
  {
    label: "Manang Disyang",
    value: "Manang Disyang",
  },
  {
    label: "Nason",
    value: "Nason",
  },
  {
    label: "Chame",
    value: "Chame",
  },
  {
    label: "Narpa Bhumi",
    value: "Narpa Bhumi",
  },
  {
    label: "Gharapjhong",
    value: "Gharapjhong",
  },
  {
    label: "Thasang",
    value: "Thasang",
  },
  {
    label: "Baragung Muktichhetra",
    value: "Baragung Muktichhetra",
  },
  {
    label: "Lomanthang",
    value: "Lomanthang",
  },
  {
    label: "Lo-Ghekar Damodarkunda",
    value: "Lo-Ghekar Damodarkunda",
  },
  {
    label: "Malika",
    value: "Malika",
  },
  {
    label: "Mangala",
    value: "Mangala",
  },
  {
    label: "Raghuganga",
    value: "Raghuganga",
  },
  {
    label: "Dhaulagiri",
    value: "Dhaulagiri",
  },
  {
    label: "Annapurna",
    value: "Annapurna",
  },
  {
    label: "Hupsekot",
    value: "Hupsekot",
  },
  {
    label: "Binayi Triveni",
    value: "Binayi Triveni",
  },
  {
    label: "Bulingtar",
    value: "Bulingtar",
  },
  {
    label: "Baudikali",
    value: "Baudikali",
  },
  {
    label: "Jaljala",
    value: "Jaljala",
  },
  {
    label: "Modi",
    value: "Modi",
  },
  {
    label: "Painyu",
    value: "Painyu",
  },
  {
    label: "Bihadi",
    value: "Bihadi",
  },
  {
    label: "Mahashila",
    value: "Mahashila",
  },
  {
    label: "Kaligandaki",
    value: "Kaligandaki",
  },
  {
    label: "Biruwa",
    value: "Biruwa",
  },
  {
    label: "Harinas",
    value: "Harinas",
  },
  {
    label: "Aandhikhola",
    value: "Aandhikhola",
  },
  {
    label: "Arjun Chaupari",
    value: "Arjun Chaupari",
  },
  {
    label: "Phedikhola",
    value: "Phedikhola",
  },
  {
    label: "Rishing",
    value: "Rishing",
  },
  {
    label: "Myagde",
    value: "Myagde",
  },
  {
    label: "Aanbu Khaireni",
    value: "Aanbu Khaireni",
  },
  {
    label: "Bandipur",
    value: "Bandipur",
  },
  {
    label: "Ghiring",
    value: "Ghiring",
  },
  {
    label: "Devghat",
    value: "Devghat",
  },
  {
    label: "Malarani",
    value: "Malarani",
  },
  {
    label: "Pandini",
    value: "Pandini",
  },
  {
    label: "Chhatradev",
    value: "Chhatradev",
  },
  {
    label: "Raptisonari",
    value: "Raptisonari",
  },
  {
    label: "Baijnath",
    value: "Baijnath",
  },
  {
    label: "Khajura",
    value: "Khajura",
  },
  {
    label: "Janaki",
    value: "Janaki",
  },
  {
    label: "Duduwa",
    value: "Duduwa",
  },
  {
    label: "Narainapur",
    value: "Narainapur",
  },
  {
    label: "Badhaiyatal",
    value: "Badhaiyatal",
  },
  {
    label: "Geruwa",
    value: "Geruwa",
  },
  {
    label: "Rapti",
    value: "Rapti",
  },
  {
    label: "Gadhawa",
    value: "Gadhawa",
  },
  {
    label: "Babai",
    value: "Babai",
  },
  {
    label: "Shantinagar",
    value: "Shantinagar",
  },
  {
    label: "Rajpur",
    value: "Rajpur",
  },
  {
    label: "Banglachuli",
    value: "Banglachuli",
  },
  {
    label: "Dangisharan",
    value: "Dangisharan",
  },
  {
    label: "Satyawati",
    value: "Satyawati",
  },
  {
    label: "Dhurkot",
    value: "Dhurkot",
  },
  {
    label: "Gulmi Durbar",
    value: "Gulmi Durbar",
  },
  {
    label: "Madane",
    value: "Madane",
  },
  {
    label: "Chandrakot",
    value: "Chandrakot",
  },
  {
    label: "Malika",
    value: "Malika",
  },
  {
    label: "Chhatrakot",
    value: "Chhatrakot",
  },
  {
    label: "Isma",
    value: "Isma",
  },
  {
    label: "Kaligandaki",
    value: "Kaligandaki",
  },
  {
    label: "Ruru",
    value: "Ruru",
  },
  {
    label: "Mayadevi",
    value: "Mayadevi",
  },
  {
    label: "Shuddhodhan",
    value: "Shuddhodhan",
  },
  {
    label: "Yasodhara",
    value: "Yasodhara",
  },
  {
    label: "Bijaynagar",
    value: "Bijaynagar",
  },
  {
    label: "Triveni Susta",
    value: "Triveni Susta",
  },
  {
    label: "Pratappur",
    value: "Pratappur",
  },
  {
    label: "Sarawal",
    value: "Sarawal",
  },
  {
    label: "Palhi Nandan",
    value: "Palhi Nandan",
  },
  {
    label: "Rainadevi Chhahara",
    value: "Rainadevi Chhahara",
  },
  {
    label: "Mathagadhi",
    value: "Mathagadhi",
  },
  {
    label: "Nisdi",
    value: "Nisdi",
  },
  {
    label: "Bagnaskali",
    value: "Bagnaskali",
  },
  {
    label: "Rambha",
    value: "Rambha",
  },
  {
    label: "Purbakhola",
    value: "Purbakhola",
  },
  {
    label: "Tinau",
    value: "Tinau",
  },
  {
    label: "Ribdikot",
    value: "Ribdikot",
  },
  {
    label: "Naubahini",
    value: "Naubahini",
  },
  {
    label: "Jhimaruk",
    value: "Jhimaruk",
  },
  {
    label: "Gaumukhi",
    value: "Gaumukhi",
  },
  {
    label: "Airawati",
    value: "Airawati",
  },
  {
    label: "Sarumarani",
    value: "Sarumarani",
  },
  {
    label: "Mallarani",
    value: "Mallarani",
  },
  {
    label: "Mandavi",
    value: "Mandavi",
  },
  {
    label: "Sunil Smriti",
    value: "Sunil Smriti",
  },
  {
    label: "Runtigadhi",
    value: "Runtigadhi",
  },
  {
    label: "Lungri",
    value: "Lungri",
  },
  {
    label: "Triveni",
    value: "Triveni",
  },
  {
    label: "Paribartan",
    value: "Paribartan",
  },
  {
    label: "Gangadev",
    value: "Gangadev",
  },
  {
    label: "Madi",
    value: "Madi",
  },
  {
    label: "Sunchhahari",
    value: "Sunchhahari",
  },
  {
    label: "Thawang",
    value: "Thawang",
  },
  {
    label: "Bhume",
    value: "Bhume",
  },
  {
    label: "Putha Uttarganga",
    value: "Putha Uttarganga",
  },
  {
    label: "Sisne",
    value: "Sisne",
  },
  {
    label: "Gaidhawa",
    value: "Gaidhawa",
  },
  {
    label: "Mayadevi",
    value: "Mayadevi",
  },
  {
    label: "Kotahimai",
    value: "Kotahimai",
  },
  {
    label: "Marchawarimai",
    value: "Marchawarimai",
  },
  {
    label: "Siyari",
    value: "Siyari",
  },
  {
    label: "Sammarimai",
    value: "Sammarimai",
  },
  {
    label: "Rohini",
    value: "Rohini",
  },
  {
    label: "Shuddhodhan",
    value: "Shuddhodhan",
  },
  {
    label: "Om Satiya",
    value: "Om Satiya",
  },
  {
    label: "Kanchan",
    value: "Kanchan",
  },
  {
    label: "Gurans",
    value: "Gurans",
  },
  {
    label: "Bhairabi",
    value: "Bhairabi",
  },
  {
    label: "Naumule",
    value: "Naumule",
  },
  {
    label: "Mahabu",
    value: "Mahabu",
  },
  {
    label: "Thantikandh",
    value: "Thantikandh",
  },
  {
    label: "Bhagawatimai",
    value: "Bhagawatimai",
  },
  {
    label: "Dungeshwar",
    value: "Dungeshwar",
  },
  {
    label: "Mudkechula",
    value: "Mudkechula",
  },
  {
    label: "Kaike",
    value: "Kaike",
  },
  {
    label: "She Phoksundo",
    value: "She Phoksundo",
  },
  {
    label: "Jagadulla",
    value: "Jagadulla",
  },
  {
    label: "Dolpo Buddha",
    value: "Dolpo Buddha",
  },
  {
    label: "Chharka Tangsong",
    value: "Chharka Tangsong",
  },
  {
    label: "Simkot",
    value: "Simkot",
  },
  {
    label: "Sarkegad",
    value: "Sarkegad",
  },
  {
    label: "Adanchuli",
    value: "Adanchuli",
  },
  {
    label: "Kharpunath",
    value: "Kharpunath",
  },
  {
    label: "Tanjakot",
    value: "Tanjakot",
  },
  {
    label: "Chankheli",
    value: "Chankheli",
  },
  {
    label: "Namkha",
    value: "Namkha",
  },
  {
    label: "Junichande",
    value: "Junichande",
  },
  {
    label: "Kuse",
    value: "Kuse",
  },
  {
    label: "Barekot",
    value: "Barekot",
  },
  {
    label: "Shivalaya",
    value: "Shivalaya",
  },
  {
    label: "Tatopani",
    value: "Tatopani",
  },
  {
    label: "Patarasi",
    value: "Patarasi",
  },
  {
    label: "Tila",
    value: "Tila",
  },
  {
    label: "Kanaka Sundari",
    value: "Kanaka Sundari",
  },
  {
    label: "Sinja",
    value: "Sinja",
  },
  {
    label: "Hima",
    value: "Hima",
  },
  {
    label: "Guthichaur",
    value: "Guthichaur",
  },
  {
    label: "Narharinath",
    value: "Narharinath",
  },
  {
    label: "Palata",
    value: "Palata",
  },
  {
    label: "Shubha Kalika",
    value: "Shubha Kalika",
  },
  {
    label: "Sanni Triveni",
    value: "Sanni Triveni",
  },
  {
    label: "Pachaljharana",
    value: "Pachaljharana",
  },
  {
    label: "Mahawai",
    value: "Mahawai",
  },
  {
    label: "Khatyad",
    value: "Khatyad",
  },
  {
    label: "Soru",
    value: "Soru",
  },
  {
    label: "Mugum Karmarong",
    value: "Mugum Karmarong",
  },
  {
    label: "Sani Bheri",
    value: "Sani Bheri",
  },
  {
    label: "Triveni",
    value: "Triveni",
  },
  {
    label: "Banphikot",
    value: "Banphikot",
  },
  {
    label: "Kumakh",
    value: "Kumakh",
  },
  {
    label: "Kalimati",
    value: "Kalimati",
  },
  {
    label: "Chhatreshwari",
    value: "Chhatreshwari",
  },
  {
    label: "Darma",
    value: "Darma",
  },
  {
    label: "Kapurkot",
    value: "Kapurkot",
  },
  {
    label: "Triveni",
    value: "Triveni",
  },
  {
    label: "Siddha Kumakh",
    value: "Siddha Kumakh",
  },
  {
    label: "Barahatal",
    value: "Barahatal",
  },
  {
    label: "Simta",
    value: "Simta",
  },
  {
    label: "Chaukune",
    value: "Chaukune",
  },
  {
    label: "Chingad",
    value: "Chingad",
  },
  {
    label: "Ramaroshan",
    value: "Ramaroshan",
  },
  {
    label: "Chaurpati",
    value: "Chaurpati",
  },
  {
    label: "Turmakhand",
    value: "Turmakhand",
  },
  {
    label: "Mellekh",
    value: "Mellekh",
  },
  {
    label: "Dhankari",
    value: "Dhankari",
  },
  {
    label: "Bannigadi Jayagad",
    value: "Bannigadi Jayagad",
  },
  {
    label: "Dogdakedar",
    value: "Dogdakedar",
  },
  {
    label: "Dilashaini",
    value: "Dilashaini",
  },
  {
    label: "Sigas",
    value: "Sigas",
  },
  {
    label: "Pancheshwar",
    value: "Pancheshwar",
  },
  {
    label: "Surnaya",
    value: "Surnaya",
  },
  {
    label: "Shivanath",
    value: "Shivanath",
  },
  {
    label: "Kedarsyu",
    value: "Kedarsyu",
  },
  {
    label: "Thalara",
    value: "Thalara",
  },
  {
    label: "Bitthadchir",
    value: "Bitthadchir",
  },
  {
    label: "Chhabis Pathibhera",
    value: "Chhabis Pathibhera",
  },
  {
    label: "Chhanna",
    value: "Chhanna",
  },
  {
    label: "Masta",
    value: "Masta",
  },
  {
    label: "Durgathali",
    value: "Durgathali",
  },
  {
    label: "Talkot",
    value: "Talkot",
  },
  {
    label: "Surma",
    value: "Surma",
  },
  {
    label: "Saipal",
    value: "Saipal",
  },
  {
    label: "Khaptad Chhededaha",
    value: "Khaptad Chhededaha",
  },
  {
    label: "Swami Kartik Khapar",
    value: "Swami Kartik Khapar",
  },
  {
    label: "Jagannath",
    value: "Jagannath",
  },
  {
    label: "Himali",
    value: "Himali",
  },
  {
    label: "Gaumul",
    value: "Gaumul",
  },
  {
    label: "Navadurga",
    value: "Navadurga",
  },
  {
    label: "Aalitaal",
    value: "Aalitaal",
  },
  {
    label: "Ganyapadhura",
    value: "Ganyapadhura",
  },
  {
    label: "Bhageshwar",
    value: "Bhageshwar",
  },
  {
    label: "Ajaymeru",
    value: "Ajaymeru",
  },
  {
    label: "Naugad",
    value: "Naugad",
  },
  {
    label: "Malikarjun",
    value: "Malikarjun",
  },
  {
    label: "Marma",
    value: "Marma",
  },
  {
    label: "Lekam",
    value: "Lekam",
  },
  {
    label: "Duhu",
    value: "Duhu",
  },
  {
    label: "Vyans",
    value: "Vyans",
  },
  {
    label: "Api Himal",
    value: "Api Himal",
  },
  {
    label: "Aadarsha",
    value: "Aadarsha",
  },
  {
    label: "Purbichauki",
    value: "Purbichauki",
  },
  {
    label: "K.I. Singh",
    value: "K.I. Singh",
  },
  {
    label: "Jorayal",
    value: "Jorayal",
  },
  {
    label: "Sayal",
    value: "Sayal",
  },
  {
    label: "Bogatan-Fudsil",
    value: "Bogatan-Fudsil",
  },
  {
    label: "Badikedar",
    value: "Badikedar",
  },
  {
    label: "Janaki Rural Municipality",
    value: "Janaki Rural Municipality",
  },
  {
    label: "Kailari Rural Municipality",
    value: "Kailari Rural Municipality",
  },
  {
    label: "Joshipur",
    value: "Joshipur",
  },
  {
    label: "Bardagoriya Rural Municipality",
    value: "Bardagoriya Rural Municipality",
  },
  {
    label: "Mohanyal Rural Municipality",
    value: "Mohanyal Rural Municipality",
  },
  {
    label: "Chure Rural Municipality",
    value: "Chure Rural Municipality",
  },
  {
    label: "Laljhadi",
    value: "Laljhadi",
  },
  {
    label: "Beldandi",
    value: "Beldandi",
  },
  {
    label: "Janakpur",
    value: "Janakpur",
  },
  {
    label: "Ghorahi",
    value: "Ghorahi",
  },
  {
    label: "Hetauda",
    value: "Hetauda",
  },
  {
    label: "Dhangadhi",
    value: "Dhangadhi",
  },
  {
    label: "Tulsipur",
    value: "Tulsipur",
  },
  {
    label: "Itahari",
    value: "Itahari",
  },
  {
    label: "Nepalgunj",
    value: "Nepalgunj",
  },
  {
    label: "Butwal",
    value: "Butwal",
  },
  {
    label: "Dharan",
    value: "Dharan",
  },
  {
    label: "Kalaiya",
    value: "Kalaiya",
  },
  {
    label: "Jitpur Simara",
    value: "Jitpur Simara",
  },
  {
    label: "Laxminiya",
    value: "laxminiya",
  },
];
