import "./App.css";
import "./Styles/main.scss";
import AuthProvider from "./Components/Context/AuthContext";
import Router from "./Components/Router/Router";
import { useEffect } from "react";
import { Crisp } from "crisp-sdk-web";
import { ModalContextProvider } from "./Components/Context/ModalContext/ModalContext";
import {
  GlobalContextProvider
} from "./Components/Context/GlobalContext";
import BidProvider from "./Components/Context/BidContext/BidContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})
function App() {
  useEffect(() => {
    Crisp.configure("f7b2fe3e-834c-4297-8f47-3b9202a4f65e");
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <GlobalContextProvider>
            <ModalContextProvider>
              <AuthProvider>
                <BidProvider>
                  <Router />
                </BidProvider>
              </AuthProvider>
            </ModalContextProvider>
          </GlobalContextProvider>
        </div>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}

export default App;
