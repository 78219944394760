import { useEffect, useState } from "react";
import Modal from "react-modal";
import { AdShow } from "../../Components/AdShow/AdShow";
import { useAuth } from "../../Components/Context/AuthContext";
import EditCompanyDetails from "../../Components/EditDetails/EditCompanyDetails";
import { ETenderSide } from "../../Components/ETenderSide/ETenderSide";
import { NoticeListing } from "../../Components/Homepage/NoticeListing";
import { ItWorks } from "../../Components/ItWorks/ItWorks";
import NewBanner from "../../Components/NewBanner/NewBanner";
import { OurClients } from "../../Components/OurClients/OurClients";
import { Testimonials } from "../../Components/Testimonials/Testimonails";
import { NoticeType } from "../../utils/EnumConstants";
import { shouldShowEditCompanyPopup } from "../../utils/services";
import { customClass } from "./constants";
import "./HomePage.scss";
import { onEbidClick, onENoticeClick } from "./utils";
import { getNotification } from "../../Components/Api/GetApi";



export const HomePage = () => {
  const { isAuthenticate, eligible, userData } = useAuth();
  const [showButton, setShowButton] = useState(false);
  const [ebidCount, setEbidCount] = useState(0);
  const [eNoticeCount, setENoticeCount] = useState(0);
  const [isCDOpen, setIsCDOpen] = useState(false);
  const [defaultTabIndex, setDefaultTabIndex] = useState(0);
  const { showCompanyPopup } = shouldShowEditCompanyPopup(
    isAuthenticate,
    eligible,
    userData?.email
  );

  const toggleCDModal = () => {
    setIsCDOpen(!isCDOpen);
  };

  useEffect(() => {
    if (showCompanyPopup) {
      toggleCDModal();
    }
  }, [showCompanyPopup]);


  useEffect(() => {
    getNotification().then((res) => {
      setEbidCount(res?.data?.data?.["e-bids"]);
      setENoticeCount(res?.data?.data?.["e-notice"]);
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200 && !showButton) {
        setShowButton(true);
        return;
      }
      if (window.scrollY === 0) {
        setShowButton(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleEBidsViewAll = (): void => {
    onEbidClick(ebidCount);
    setDefaultTabIndex(1)
  };

  const handleENoticeViewAll = (): void => {
    onENoticeClick(eNoticeCount);
    setDefaultTabIndex(2)
  };

  const handleLaterClick = () => {
    const companyPopupData = localStorage.getItem("loginDataArray");

    if (companyPopupData) {
      let parsedData = JSON.parse(companyPopupData);

      parsedData = parsedData.filter(
        (data: { firstLoginEmail: string }) =>
          data.firstLoginEmail !== userData?.email
      );

      localStorage.setItem("loginDataArray", JSON.stringify(parsedData));
    }
    toggleCDModal?.();
  };


  return (
    <div className="home-page">
      {showButton && (
        <img
          className={`scroll-top-btn`}
          src={require("../../images/scroll-top.svg").default}
          alt="scroll-to-top"
          onClick={handleClick}
        />
      )}
      {showCompanyPopup && (
        <Modal
          isOpen={isCDOpen}
          onRequestClose={() => {
            if (!eligible) {
              handleLaterClick();
            } else {
              toggleCDModal();
            }
          }}
          contentLabel="My dialog"
          style={customClass}
          ariaHideApp={false}
        >
          <EditCompanyDetails
            toggleModal={() => toggleCDModal()}
            handleLaterClick={handleLaterClick}
          />
        </Modal>
      )}
      <div className="home-page">
        {showButton && (
          <img
            className={`scroll-top-btn`}
            src={require("../../images/scroll-top.svg").default}
            alt="scroll-to-top"
            onClick={handleClick}
          />
        )}
        <NewBanner />
        <div className="card-wrapper-content">
          <div className="container">
            <div className="row">
              <div className="col-md-9 ">
                <div className="mg-top-bid"></div>
                <NoticeListing
                  defaultTabIndex={defaultTabIndex}
                  eNoticeCount={eNoticeCount}
                  ebidCount={ebidCount}
                />
              </div>
              <div className="col-md-3">
                <div className="mg-top-bid"></div>
                <div className="home-sidebar">
                  <div className="mb-4">
                    <ETenderSide
                      handleViewAll={handleEBidsViewAll}
                      sectionTitle={"E-bids"}
                      theme="Secondary"
                      noticeType={NoticeType.EBID}
                    />
                  </div>
                  <div className="mb-4">
                    <ETenderSide
                      handleViewAll={handleENoticeViewAll}
                      sectionTitle={"E-Notices"}
                      noticeType={NoticeType.ENOTICE}
                      theme="Primary"
                    />
                  </div>
                  <div className="sidebar-sticky">
                    <AdShow />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ItWorks />
        <Testimonials />
        <OurClients />
      </div>
    </div>
  );
};
